import { useState } from 'react';
import { Stack, IconButton, Typography } from '@mui/material/'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmDialog from '../dialog/ConfirmDialog';
import MaterialTable from "@material-table/core";
import DeleteIcon from '@mui/icons-material/Delete';
import { hostname } from '../../hostname';
import axios from 'axios'
import Snackbar from '../../utils/Snackbar';

function DeviceManagerDialog(props) {
    const { deviceData, setOpen, open, getDevice } = props;
    const [confirmDialog, setConfirmDialog] = useState({ open: false, msg: '' })
    const [openSnackbar, setOpenSnackbar] = useState({ status: false, type: "", msg: "" });

    const onClickRemoveDevice = () => {
        setConfirmDialog({ open: true, msg: 'Are you sure to delete this device ?' })
    }

    const removeDevice = async () => {
        try {
            let res = await axios({
                method: "post",
                url: `${hostname}/auth/disConnect`,
                headers: {
                    checkDevice: false
                },
                data: {
                    "DeviceID": deviceData[0]?.DeviceID
                },
            });
            if (res.data.status === "success") {
                getDevice(String(deviceData[0]?.UserID))
            }
            if (res.data.status === "fail") {
                setOpenSnackbar({ status: true, type: "error", msg: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง" });
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="dialogTitle">Device Manager</Typography>
                    <IconButton
                        disableRipple
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent >
                    <DialogContentText >
                        <Stack pt={1} pb={3}>
                            <MaterialTable
                                title=""
                                data={deviceData}
                                columns={[
                                    { title: 'Date Time', field: 'InfoDevice.DateTime' },
                                    { title: 'Browser', field: 'InfoDevice.BrowserAgent' },
                                    { title: 'OS Name', field: 'InfoDevice.OsName' },
                                    { title: 'Platform', field: 'InfoDevice.PlatformType' },
                                ]}
                                actions={[
                                    {
                                        icon: () => <DeleteIcon color="primary" />,
                                        tooltip: 'Delete Device',
                                        onClick: () => onClickRemoveDevice(),
                                    }
                                ]}
                                options={{
                                    padding: 'dense',
                                    pageSize: 3,
                                    paging: false,
                                    pageSizeOptions: false,
                                }}
                                localization={{
                                    header: {
                                        actions: '',
                                    },
                                }}
                            />

                        </Stack>
                    </DialogContentText>
                </DialogContent >
            </Dialog >

            <ConfirmDialog
                open={confirmDialog.open}
                onclose={bool => setConfirmDialog({ ...confirmDialog, 'open': bool })}
                msg={confirmDialog.msg}
                confirm={bool => bool ? removeDevice() : null}
            />
            <Snackbar values={openSnackbar} setValues={setOpenSnackbar} />
        </>
    )
}

export default DeviceManagerDialog