import {IconDatabase , IconFolders,IconSend} from '@tabler/icons';

const icons = {
    IconDatabase,
    IconFolders,
    IconSend
};

const DataBreachMenu = 
    {
        id: 'Data Breach Management',
        title: 'Data Breach Management',
        type: 'collapse',
        icon: icons.IconDatabase,
        children: [
            {
                id: 'Data Breach',
                title: 'Data Breach',
                type: 'item',
                url: '/data-breach/data-breach',
                icon: icons.IconSend,
                breadcrumbs: false,
               
            },
          
 
        ]
    }

export default DataBreachMenu
