import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Routes from './routes';
import themes from './themes';
import NavigationScroll from './layout/NavigationScroll';
import axios from "axios";
import { useRef, useContext, useEffect } from 'react';
import LoadingModal from './utils/LoadingModal';
import SnackbarRef from './utils/SnackbarRef'
import { UserContext } from './components/contexts/UserContext'
import Cookies from 'universal-cookie';
import logoutFunc from './utils/logout'

function App() {
    const hostname = process.env.REACT_APP_API
    const { setUserDataContext } = useContext(UserContext)
    const loadingModalRef = useRef(null);
    const snackbarRef = useRef(null);
    const isPageLogin = new URL(window.location.href).pathname === "/login";
    const cookies = new Cookies()

    axios.defaults.withCredentials = true;
    axios.interceptors.request.use(
        async function (config) {
            loadingModalRef.current.setOpen(true)
            return config;
        },
        function (error) {
            loadingModalRef.current.setOpen(false)
            snackbarRef.current.setSnackValue({ status: true, type: 'error', message: error.toString() })
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        async function (response) {
            loadingModalRef.current.setOpen(false)
            if (response.data.status === "not found device" || response.data.status === "no found device active") {
                await logoutFunc.logout()
            }
            if (response.data.status === "User Expired") {
                snackbarRef.current.setSnackValue({ status: true, type: "error", message: "ผู้ใช้หมดอายุ กรุณาติดต่อผู้ดูแลระบบ" })
                if (!isPageLogin) {
                    await logoutFunc.logout()
                }
            }
            return response;
        },
        async function (error) {
            loadingModalRef.current.setOpen(false)
            snackbarRef.current.setSnackValue({ status: true, type: 'error', message: error.toString() })
            if (!isPageLogin && error.response.status === 401) {
                await logoutFunc.logout()
            }
            if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
                await logoutFunc.logout()
            }
            return Promise.reject(error);
        }
    );

    const customization = useSelector((state) => state.customization);


    const getUserProfile = async () => {
        try {
            let res = await axios({
                method: "post",
                url: `${hostname}/profile/getProfile`,
                data: {},
            });
            if (res.data.status === "success") {
                let user = res.data.data
                setUserDataContext({
                    userProfile: {
                        Username: user.Username,
                        FirstName: user.FirstName,
                        LastName: user.LastName,
                        Position: user.Description,
                        PositionID: user.PositionID,
                        UserID: user.UserID,
                        ProfileImagePath: user.ProfileImagePath,
                        GUID: user.GUID,
                        Language: user.LanguageCode,
                        CompanyID: user.CompanyID,
                        CompanyName: user.CompanyName,
                        Role: user.Role,
                        RoleID: user.RoleID,
                    }
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (cookies.get('isLoggedIn') === 'true' && cookies.get('isLoggedIn') !== undefined) {
            getUserProfile()
        }
    }, [])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                    <LoadingModal ref={loadingModalRef} />
                    <SnackbarRef ref={snackbarRef} />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
