import { IconFileText } from '@tabler/icons';

import FolderSharedIcon from '@mui/icons-material/FolderShared';

const PDInventoryMenu = {
    id: 'Personal Data Management',
    title: 'Personal Data Management',
    type: 'collapse',
    icon: FolderSharedIcon,
    children: [
        {
            id: 'PD Inventory',
            title: 'PD Inventory',
            type: 'item',
            url: '/pdm/pd-inventory',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Lawful Basis',
            title: 'Lawful Basis',
            type: 'item',
            url: '/pdm/lawful-basis',
            breadcrumbs: false,
            icon: IconFileText
        },
    ]
}

export default PDInventoryMenu
