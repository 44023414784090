import React, { createContext, useReducer } from "react";

const PermissionState = {
    menu_code: "",
    pageAction: []
}

const PermissionContext = createContext(PermissionState)

const PermissionContextProvider = ({ children }) => {
    const [state, menuDispatch] = useReducer(PermissionReducer, PermissionState)

    const setMenuCode = (payload) => {
        menuDispatch({ type: "SET_MENU_CODE", payload })
    }

    const checkPermissionAccess = (payload) => {
        menuDispatch({ type: "CHECK_PERMISSION_ACCESS", payload })
    }

    const setPageAction = (payload) => {
        menuDispatch({ type: "SET_PAGE_ACTION", payload })
    }

    const func = {
        setMenuCode,
        setPageAction,
        checkPermissionAccess,
        ...state
    }

    return (
        <PermissionContext.Provider value={func}>
            {children}
        </PermissionContext.Provider>
    )
}
export { PermissionContext, PermissionContextProvider }

const PermissionReducer = (state, action) => {
    switch (action.type) {
        case "SET_PAGE_ACTION":
            return {
                ...state,
                pageAction: action.payload,
            }
        case "CHECK_PERMISSION_ACCESS":
            return {
                ...state,
                pageAction: action.payload,
            }
        case "SET_MENU_CODE":
            return {
                ...state,
                menu_code: action.payload,
            }

        default:
            return state
    }
}