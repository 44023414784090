import { IconDatabase, IconFileText } from '@tabler/icons';

const MasterDataMenu =
{
    id: 'MasterData',
    title: 'Master Data',
    type: 'collapse',
    icon: IconDatabase,
    children: [
        {
            id: 'Task project',
            title: 'Task project',
            type: 'item',
            url: '/master-data/task-project',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Risk response options',
            title: 'Risk response options',
            type: 'item',
            url: '/master-data/risk-response-options',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Impact',
            title: 'Impact',
            type: 'item',
            url: '/master-data/impact',
            breadcrumbs: false,
            icon: IconFileText
        }, {
            id: 'Title',
            title: 'Title',
            type: 'item',
            url: '/master-data/title',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Position',
            title: 'Position',
            type: 'item',
            url: '/master-data/position',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Department',
            title: 'Department',
            type: 'item',
            url: '/master-data/department',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Category of individual',
            title: 'Category of individual',
            type: 'item',
            url: '/master-data/category-of-individual',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Category of personal data',
            title: 'Category of personal data',
            type: 'item',
            url: '/master-data/category-of-personal-data',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Location of personal data',
            title: 'Location of personal data',
            type: 'item',
            url: '/master-data/location-of-personal-data',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Source of personal data',
            title: 'Source of personal data',
            type: 'item',
            url: '/master-data/source-of-personal-data',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Purpose',
            title: 'Purpose',
            type: 'item',
            url: '/master-data/purpose',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Website',
            title: 'Website',
            type: 'item',
            url: '/master-data/website',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Data controller',
            title: 'Data controller',
            type: 'item',
            url: '/master-data/data-controller',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Data protection officer',
            title: 'Data protection officer',
            type: 'item',
            url: '/master-data/data-protection-officer',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Business function',
            title: 'Business function',
            type: 'item',
            url: '/master-data/business-function',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Measures to maintain safety',
            title: 'Measures to maintain safety',
            type: 'item',
            url: '/master-data/measures-to-maintain-safety',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Personal data to keep',
            title: 'Personal data to keep',
            type: 'item',
            url: '/master-data/personal-data-to-keep',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Time to keep',
            title: 'Time to keep',
            type: 'item',
            url: '/master-data/time-to-keep',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Right to Access',
            title: 'Right to Access',
            type: 'item',
            url: '/master-data/right-to-access',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Right to data portability',
            title: 'Right to data portability',
            type: 'item',
            url: '/master-data/right-to-data-portability',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Right to object',
            title: 'Right to object',
            type: 'item',
            url: '/master-data/right-to-object',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Right to rectification',
            title: 'Right to rectification',
            type: 'item',
            url: '/master-data/right-to-rectification',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Processing agreement',
            title: 'Processing agreement',
            type: 'item',
            url: '/master-data/processing-agreement',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Agency',
            title: 'Agency',
            type: 'item',
            url: '/master-data/agency',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Notice owner data status',
            title: 'Notice owner data status',
            type: 'item',
            url: '/master-data/notice-owner-data-status',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Data breach status',
            title: 'Data breach status',
            type: 'item',
            url: '/master-data/data-breach-status',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Valuation type',
            title: 'Valuation type',
            type: 'item',
            url: '/master-data/valuation-type',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Impact type',
            title: 'Impact type',
            type: 'item',
            url: '/master-data/impact-type',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Threat type',
            title: 'Threat type',
            type: 'item',
            url: '/master-data/threat-type',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Risk acceptance',
            title: 'Risk acceptance',
            type: 'item',
            url: '/master-data/risk-acceptance',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Vulnerability',
            title: 'Vulnerability',
            type: 'item',
            url: '/master-data/vulnerability',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Threat',
            title: 'Threat',
            type: 'item',
            url: '/master-data/threat',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Likelihood',
            title: 'Likelihood',
            type: 'item',
            url: '/master-data/likelihood',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Valuation',
            title: 'Valuation',
            type: 'item',
            url: '/master-data/valuation',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Type of request',
            title: 'Type of request',
            type: 'item',
            url: '/master-data/type-of-request',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Type of reject',
            title: 'Type of reject',
            type: 'item',
            url: '/master-data/type-of-reject',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Reject request',
            title: 'Reject request',
            type: 'item',
            url: '/master-data/reject-request',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Location',
            title: 'Location',
            type: 'item',
            url: '/master-data/location',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Asset data type',
            title: 'Asset data type',
            type: 'item',
            url: '/master-data/asset-data-type',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Lia activity',
            title: 'Lia activity',
            type: 'item',
            url: '/master-data/lia-activity',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Lia likelihood',
            title: 'Lia likelihood',
            type: 'item',
            url: '/master-data/lia-likelihood',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Lia personal data subject',
            title: 'Lia personal data subject',
            type: 'item',
            url: '/master-data/lia-personal-data-subject',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Lia priority category',
            title: 'Lia priority category',
            type: 'item',
            url: '/master-data/lia-priority-category',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Lia rule and notices',
            title: 'Lia rule and notices',
            type: 'item',
            url: '/master-data/lia-rule-and-notices',
            breadcrumbs: false,
            icon: IconFileText
        },

    ]
}

export default MasterDataMenu;