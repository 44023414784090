const axios = require("axios");
const hostname = process.env.REACT_APP_API

const getSignedURL = async (key) => {
    try {
        let res = await axios({
            method: "post",
            url: `${hostname}/signedUrl/getSignedUrl`,
            data: { key: key },
        });
        if (res.status === 200) {
            return res.data
        }
    } catch (error) {
        console.log(error);
    }
}

const uploadSignedUrl = async (key, file, type) => {
    let contentType = ''
    if (type === 'image') {
        contentType = 'multipart/form-data'
    }
    if (type === 'zip') {
        contentType = 'application/octet-stream'
    }
    if (type === 'pdf') {
        contentType = 'application/pdf'
    }
    try {
        let res = await axios({
            method: "post",
            url: `${hostname}/signedUrl/getUploadSignedUrl`,
            data: { key: key, type: type },
        });
        if (res.status === 200) {
            await fetch(res.data, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': contentType
                }
            })
            return ('success')
        }
    } catch (error) {
        console.log(error);
    }
}

export default { getSignedURL, uploadSignedUrl }