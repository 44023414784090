import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, Divider, Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import SaveIcon from '@mui/icons-material/Save';
import TextFieldCus from "../../components/form/TextFieldCus";
import Snackbar from '../../utils/Snackbar';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import InputMask from "react-input-mask";
import axios from 'axios';
import FormHelperText from '@mui/material/FormHelperText';

function DataSubjectURL(props) {
    const hostname = process.env.REACT_APP_API
    const [rowData, setRowData] = useState({})
    const [typeOfRequest, setTypeOfRequest] = useState([])
    const [openSnackbar, setOpenSnackbar] = useState({ status: false, type: "", msg: "" });
    const navigate = useNavigate();

    const validateSchema = Yup.object().shape({
        Email: Yup.string().email("กรุณากรอกอีเมล์ให้ถูกต้อง").required("กรุณากรอกอีเมล์"),
        NameSurname: Yup.string().required("กรุณากรอกชื่อ"),
        Address: Yup.string().required("กรุณากรอกที่อยู่"),
        PersonalData_Email: Yup.string().email("กรุณากรอกอีเมล์ให้ถูกต้อง").required("กรุณากรอกอีเมล์"),
        PersonalData_NameSurname: Yup.string().required("กรุณากรอกชื่อ"),
        PersonalData_Address: Yup.string().required("กรุณากรอกที่อยู่"),
        TypeOfRequestID: Yup.string().required("กรุณาเลือกประเภทของคำขอ"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm({
        resolver: yupResolver(validateSchema),
        mode: "onChange"
    });

    const handleChangeData = async (e) => {
        setRowData({ ...rowData, [e.target.name]: e.target.value })
    }
    const handleChangeDataCheck = async (e) => {
        if (e.target.name === "IsOwnPersonalData" && e.target.checked === true) {
            setValue("PersonalData_Email", rowData.Email)
            setValue("PersonalData_NameSurname", rowData.NameSurname)
            setValue("PersonalData_Address", rowData.Address)
            return setRowData({
                ...rowData,
                PersonalData_Email: rowData.Email,
                PersonalData_NameSurname: rowData.NameSurname,
                PersonalData_Address: rowData.Address,
                PersonalData_TelephoneNo: rowData.TelephoneNo,
                IsOwnPersonalData: e.target.checked
            })
        }
        setRowData({ ...rowData, [e.target.name]: e.target.checked })
    }

    const getTypeOfRequestAll = async () => {
        try {
            let res = await axios({
                url: `${hostname}/data_subject/getTypeOfRequest`,
                method: "POST",
                headers: {
                    checkDevice: false
                },
            })
            if (res.data.status === "success") {

                await setTypeOfRequest(res.data.data)
            }
        } catch (error) {
            console.log(error);

        }
    }

    const addDataSubjectRight = async () => {
        try {
            rowData.CompanyID = (new URLSearchParams(window.location.search)).get('CompanyID');
            let res = await axios({
                url: `${hostname}/data_subject/addDataSubjectRightPublic`,
                method: "POST",
                data: rowData,
                headers: {
                    checkDevice: false
                },
            })
            if (res.data.status === "success") {
                setOpenSnackbar({ status: true, type: "success", msg: "บันทึกสำเร็จ" });
                setRowData({})
            }

        } catch (error) {
            console.log(error);

        }
    }

    const checkAccessToken = async () => {
        const access_token = (new URLSearchParams(window.location.search)).get('CompanyID');
        let allAccessToken = await getAllAccessToken()
        if (allAccessToken.some(item => item.access_token === access_token)) {
            return true
        }
    }

    const getAllAccessToken = async () => {
        try {
            let res = await axios({
                url: `${hostname}/user/getAllAccessToken`,
                method: "POST",
                data: {},
                headers: {
                    checkDevice: false
                },
            })
            if (res.data.status === "success") {
                return res.data.data
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(async () => {
        if (await checkAccessToken()) {
            getTypeOfRequestAll()
        } else {
            navigate('/login')
        }
    }, [])


    return (
        <>
            {/* <Paper style={{ paddingLeft: '150px', paddingRight: '150px', paddingTop: '50px', paddingBottom: '50px' }}> */}
            <Paper sx={{ py: '50px', px: 2 }}>

                <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
                    <Box sx={{ flexGrow: 1, marginTop: "10px" }}>
                        <Typography
                            style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                color: "#000000",
                                textAlign: 'center'
                            }}
                        >
                            แบบฟอร์มขอใช้สิทธิ์ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                        </Typography>


                        <Grid container spacing={1} style={{ marginTop: '50px' }}>
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    ข้อมูลของผู้ยื่นคำขอ
                                </Typography>
                                <Divider sx={{ marginBottom: 2 }} />
                                <Box m={1}>
                                    <Grid container spacing={3} p={1}>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                sx={{ flexGrow: 1, marginTop: "10px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                            >
                                                <Typography
                                                    style={{
                                                        width: '30%'
                                                    }}
                                                >
                                                    ชื่อ - สกุล (Name - Surname): <a style={{ color: "#029DBF" }}>*</a>
                                                </Typography>
                                                <Box sx={{ width: '100%' }}>
                                                    <TextField
                                                        fullWidth
                                                        placeholder='ชื่อ - สกุล (Name - Surname)'
                                                        size="small"
                                                        name="NameSurname"
                                                        value={rowData.NameSurname || ''}
                                                        {...register("NameSurname", {
                                                            onChange: (e) => {
                                                                handleChangeData(e)
                                                            }
                                                        })}
                                                        error={errors?.NameSurname}
                                                        helperText={errors?.NameSurname?.message}
                                                    />
                                                </Box>
                                            </Box>

                                            <Box
                                                sx={{ flexGrow: 1, marginTop: "10px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                            >
                                                <Typography
                                                    style={{

                                                        width: '30%'
                                                    }}
                                                >
                                                    ที่อยู่: <a style={{ color: "#029DBF" }}>*</a>
                                                </Typography>
                                                <Box sx={{ width: '100%' }}>
                                                    <TextField
                                                        fullWidth
                                                        placeholder='ที่อยู่'
                                                        size="small"
                                                        multiline
                                                        rows={3}
                                                        name="Address"
                                                        value={rowData.Address || ''}
                                                        {...register("Address", {
                                                            onChange: (e) => {
                                                                handleChangeData(e)
                                                            }
                                                        })}
                                                        error={errors?.Address}
                                                        helperText={errors?.Address?.message}
                                                    />
                                                </Box>
                                            </Box>

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                sx={{ flexGrow: 1, marginTop: "10px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                            >
                                                <Typography
                                                    style={{
                                                        width: '30%'
                                                    }}
                                                >
                                                    Email: <a style={{ color: "#029DBF" }}>*</a>
                                                </Typography>
                                                <Box sx={{ width: '100%' }}>
                                                    <TextField
                                                        fullWidth
                                                        placeholder='Email'
                                                        size="small"
                                                        name="Address"
                                                        value={rowData.Email || ''}
                                                        {...register("Email", {
                                                            onChange: (e) => {
                                                                handleChangeData(e)
                                                            }
                                                        })}
                                                        error={errors?.Email}
                                                        helperText={errors?.Email?.message}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{ flexGrow: 1, marginTop: "10px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                            >
                                                <Typography
                                                    style={{
                                                        width: '30%'
                                                    }}
                                                >
                                                    เบอร์ติดต่อ (Telephone No):
                                                </Typography>
                                                <Box sx={{ width: '100%' }}>
                                                    <InputMask
                                                        mask="9999999999"
                                                        maskChar=""
                                                        value={rowData?.TelephoneNo || ''}
                                                        onChange={(e) => handleChangeData(e)}
                                                    >
                                                        {() => (
                                                            <TextField
                                                                name="TelephoneNo"
                                                                placeholder='เบอร์ติดต่อ (Telephone No)'
                                                                fullWidth
                                                                size="small"
                                                                type="tel"
                                                            />
                                                        )}
                                                    </InputMask>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>

                            {/* ต้องการยื่นคำร้องขอเพื่อจุดประสงค์ดังต่อไปนี้ (Type of Request)*/}
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    ต้องการยื่นคำร้องขอเพื่อจุดประสงค์ดังต่อไปนี้ (Type of Request)
                                </Typography>
                                <Divider sx={{ marginBottom: 2 }} />
                                <Box m={1}>
                                    <FormControl error={errors?.TypeOfRequestID}>
                                        <i>โปรดเลือกจุดประสงค์ที่ท่านต้องการ (SELECT THE TYPE OF REQUEST OF YOUR MARKING)</i>
                                        {typeOfRequest.map(e => (
                                            <Controller
                                                name="TypeOfRequestID"
                                                control={control}
                                                render={({ field }) => {
                                                    return <RadioGroup
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e)
                                                            handleChangeData(e)
                                                        }}
                                                        value={rowData.TypeOfRequestID || ''}
                                                    >
                                                        <FormControlLabel value={e.TypeOfRequestID} control={<Radio />} label={`${e.Code} : ${e.Description}`} />
                                                    </RadioGroup>
                                                }}
                                            />
                                        ))}
                                        <FormHelperText>{errors?.TypeOfRequestID?.message}</FormHelperText>
                                    </FormControl>
                                </Box>
                            </Grid>

                            {/* ท่านเป็นเจ้าของข้อมูลหรือไม่*/}
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    ท่านเป็นเจ้าของข้อมูลหรือไม่
                                </Typography>
                                <Divider sx={{ marginBottom: 2 }} />
                                <Box m={1}>
                                    <FormGroup
                                        onChange={handleChangeDataCheck}
                                    >
                                        <FormControlLabel name="IsOwnPersonalData" control={<Checkbox />} label="ผู้ยื่นคำร้องเป็นบุคคลเดียวกับเจ้าของข้อมูล ทั้งนี้ ข้าพเจ้าได้แนบเอกสารดังต่อไปนี้ เพื่อการตรวจสอบตัวตน และที่อยู่ของผู้ยื่นคำร้องเพื่อให้บริษัทฯ สามารถดำเนินการตามสิทธิที่ร้องขอได้อย่างถูกต้อง" />
                                    </FormGroup>

                                </Box>
                            </Grid>


                            {/*เอกสารพิสูจน์ตัวตนและ/หรือพิสูจน์ถิ่นที่อยู่เจ้าของข้อมูล*/}
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    เอกสารพิสูจน์ตัวตนและ/หรือพิสูจน์ถิ่นที่อยู่เจ้าของข้อมูล
                                </Typography>
                                <Divider sx={{ marginBottom: 2 }} />
                                <Box m={1}>
                                    <FormGroup onChange={handleChangeDataCheck}>
                                        <FormControlLabel name="OwnData_CitizenCopy" control={<Checkbox />} label="สำเนาบัตรประจำตัวประชาชน(กรณีสัญชาติไทย)" />
                                        <FormControlLabel name="OwnData_PassportCopy" control={<Checkbox />} label="สำเนา Passport(กรณีต่างชาติ)" />
                                        <FormControlLabel name="OwnData_HouseRegistrationCopy" control={<Checkbox />} label="สำเนาทะเบียนบ้าน" />
                                        <FormControlLabel name="isAgent" control={<Checkbox />} label="ผู้ยื่นคำร้องเป็นตัวแทนของเจ้าของข้อมูล" />
                                        <FormControlLabel name="OwnData_Other" control={<Checkbox />} label="อื่นๆ (ถ้ามีโปรดระบุ)" />
                                    </FormGroup>
                                    <TextFieldCus
                                        placeholder='ระบุ'
                                        name={'OwnData_OtherNote'}
                                        value={rowData.OwnData_OtherNote || ''}
                                        onChange={(e) => handleChangeData(e)}
                                    />


                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    รายละเอียดเจ้าของข้อมูล
                                </Typography>
                                <Divider sx={{ marginBottom: 2 }} />
                                <Box m={1}>
                                    <Grid container spacing={3} p={1}>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                sx={{ flexGrow: 1, marginTop: "10px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                            >
                                                <Typography
                                                    style={{
                                                        width: '30%'
                                                    }}
                                                >
                                                    ชื่อ - สกุล (Name - Surname): <a style={{ color: "#029DBF" }}>*</a>
                                                </Typography>
                                                <Box sx={{ width: '100%' }}>
                                                    <TextField
                                                        fullWidth
                                                        placeholder='ชื่อ - สกุล (Name - Surname)'
                                                        size="small"
                                                        name="PersonalData_NameSurname"
                                                        value={rowData.PersonalData_NameSurname || ''}
                                                        {...register("PersonalData_NameSurname", {
                                                            onChange: (e) => {
                                                                handleChangeData(e)
                                                            }
                                                        })}
                                                        error={errors?.PersonalData_NameSurname}
                                                        helperText={errors?.PersonalData_NameSurname?.message}
                                                    />
                                                </Box>
                                            </Box>

                                            <Box
                                                sx={{ flexGrow: 1, marginTop: "10px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                            >
                                                <Typography
                                                    style={{

                                                        width: '30%'
                                                    }}
                                                >
                                                    ที่อยู่: <a style={{ color: "#029DBF" }}>*</a>
                                                </Typography>
                                                <Box sx={{ width: '100%' }}>
                                                    <TextField
                                                        fullWidth
                                                        placeholder='ที่อยู่'
                                                        size="small"
                                                        rows={3}
                                                        multiline
                                                        name="PersonalData_Address"
                                                        value={rowData.PersonalData_Address || ''}
                                                        {...register("PersonalData_Address", {
                                                            onChange: (e) => {
                                                                handleChangeData(e)
                                                            }
                                                        })}
                                                        error={errors?.PersonalData_Address}
                                                        helperText={errors?.PersonalData_Address?.message}
                                                    />
                                                </Box>
                                            </Box>

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                sx={{ flexGrow: 1, marginTop: "10px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                            >
                                                <Typography
                                                    style={{
                                                        width: '30%'
                                                    }}
                                                >
                                                    Email: <a style={{ color: "#029DBF" }}>*</a>
                                                </Typography>
                                                <Box sx={{ width: '100%' }}>
                                                    <TextField
                                                        fullWidth
                                                        placeholder='Email'
                                                        size="small"
                                                        multiline
                                                        name="PersonalData_Email"
                                                        value={rowData.PersonalData_Email || ''}
                                                        {...register("PersonalData_Email", {
                                                            onChange: (e) => {
                                                                handleChangeData(e)
                                                            }
                                                        })}
                                                        error={errors?.PersonalData_Email}
                                                        helperText={errors?.PersonalData_Email?.message}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{ flexGrow: 1, marginTop: "10px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                            >
                                                <Typography
                                                    style={{
                                                        width: '30%'
                                                    }}
                                                >
                                                    เบอร์ติดต่อ (Telephone No):
                                                </Typography>
                                                <Box sx={{ width: '100%' }}>
                                                    <InputMask
                                                        mask="9999999999"
                                                        maskChar=""
                                                        value={rowData?.PersonalData_TelephoneNo || ''}
                                                        onChange={(e) => handleChangeData(e)}
                                                    >
                                                        {() => (
                                                            <TextField
                                                                name="PersonalData_TelephoneNo"
                                                                placeholder='เบอร์ติดต่อ (Telephone No)'
                                                                fullWidth
                                                                size="small"
                                                                type="tel"
                                                            />
                                                        )}
                                                    </InputMask>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>

                            {/* ท่านเป็นเจ้าของข้อมูลหรือไม่*/}
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    ทั้งนี้ ข้าพเจ้าได้แนบเอกสารดังต่อไปนี้ เพื่อการตรวจสอบอำนาจ ตัวตน และถิ่นที่อยู่ของผู้ยื่นคำร้องและเจ้าของข้อมูล เพื่อให้บริษัทฯ สามารถดำเนิการตามสิทธิที่ร้องขอได้อย่างถูกต้อง เอกสารพิสูจน์อำนาจดำเนินการแทน
                                </Typography>
                                <Divider sx={{ marginBottom: 2 }} />
                                <Box m={1}>
                                    <FormGroup
                                        onChange={handleChangeDataCheck}
                                    >
                                        <FormControlLabel name="HavePowerOfAttorney" control={<Checkbox />} label="หนังสือมอบอำนาจ" />
                                    </FormGroup>

                                </Box>
                            </Grid>

                            {/*เอกสารพิสูจน์ตัวตนและ/หรือพิสูจน์ถิ่นที่อยู่*/}
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    เอกสารพิสูจน์ตัวตนและ/หรือพิสูจน์ถิ่นที่อยู่
                                </Typography>
                                <Divider sx={{ marginBottom: 2 }} />
                                <Box m={1}>
                                    <FormGroup onChange={handleChangeDataCheck}>
                                        <FormControlLabel name="ReplacementOperation_YourCitizenCopyAndOwnData" control={<Checkbox />} label="สำเนาบัตรประจำตัวประชาชนของท่านและเจ้าของข้อมูล(กรณีสัญชาติไทย)" />
                                        <FormControlLabel name="ReplacementOperation_YourPassportCopyAndOwnData" control={<Checkbox />} label="สำเนา Passportของท่านและเจ้าของข้อมูล(กรณีต่างชาติ)" />
                                        <FormControlLabel name="ReplacementOperation_HouseRegistartionCopy" control={<Checkbox />} label="สำเนาทะเบียนบ้านของท่านและเจ้าของข้อมูล" />
                                        <FormControlLabel name="ReplacementOperation_Other" control={<Checkbox />} label="อื่นๆ (ถ้ามีโปรดระบุ)" />
                                    </FormGroup>
                                    <TextFieldCus
                                        placeholder='ระบุ'
                                        name={'ReplacementOperation_OtherNote'}
                                        value={rowData.ReplacementOperation_OtherNote || ''}
                                        onChange={(e) => handleChangeData(e)}
                                    />
                                    <Box marginTop="10px">
                                        <i> บริษัทฯ ขอสงวนสิทธิในการสอบถามข้อมูล หรือเรียกเอกสารเพิ่มเติมจากผู้ยื่นคำร้อง หากข้อมูลที่ได้รับไม่สามารถแสดงให้เห็นได้อย่างชัดเจนได้ว่าผู้ยื่นคำร้องเป็นเจ้าของข้อมูล หรือมีอำนาจในการยื่นคำร้องขอดังกล่าว บริษัทฯ ขอสงวนสิทธิในการปฏิเสธคำร้องขอของท่าน</i>
                                    </Box>
                                </Box>
                            </Grid>

                            {/*ข้อมูลส่วนบุคคลที่ประสงค์จะให้ลบ*/}
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    ข้อมูลส่วนบุคคลที่ประสงค์จะให้ลบ
                                </Typography>
                                <Divider sx={{ marginBottom: 2 }} />

                                <Grid container spacing={2} p={1}>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            marginTop: "10px",
                                            paddingLeft: "20px",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid item xs={4}>
                                            ข้อมูลส่วนบุคคล:
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextFieldCus
                                                placeholder='ข้อมูลส่วนบุคคล'
                                                name={'Erasure_PersonalData'}
                                                value={rowData.Erasure_PersonalData || ''}
                                                onChange={(e) => handleChangeData(e)}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid container spacing={2} p={1}>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            marginTop: "10px",
                                            paddingLeft: "20px",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid item xs={4}>
                                            การดำเนินการ:
                                        </Grid>
                                        <Grid item xs={8}>
                                            <RadioGroup
                                                row
                                                name="Erasure_Operation"
                                                onChange={handleChangeData}
                                            >
                                                <FormControlLabel value="1" control={<Radio />} label="ลบ" />
                                                <FormControlLabel value="2" control={<Radio />} label="ไม่ทำลาย" />
                                                <FormControlLabel value="3" control={<Radio />} label="ทำให้ไม่สามารถระบุตัวตนเจ้าของข้อมูลได้" />
                                            </RadioGroup>
                                        </Grid>
                                    </Box>
                                </Grid>



                                <Grid container spacing={2} p={1}>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            marginTop: "10px",
                                            paddingLeft: "20px",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid item xs={4}>
                                            แหล่งที่พบ:
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextFieldCus
                                                placeholder='แหล่งที่พบ'
                                                name={'Erasure_From'}
                                                value={rowData.Erasure_From || ''}
                                                onChange={(e) => handleChangeData(e)}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>

                            </Grid>

                            {/* เหตุประกอบคำร้องขอ)*/}
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "#000000",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    เหตุประกอบคำร้องขอ
                                </Typography>
                                <Divider sx={{ marginBottom: 2 }} />
                                <Box m={1}>
                                    <FormControl>
                                        <i>กรุณาชี้แจงเหตุผลผลประกอบในการร้องขอให้ดำเนินการขอเข้าถึงข้อมูลส่วนบุคคลของเจ้าของข้อมูล พร้อมทั้งเอกสารข้อมูล หลักฐานประกอบเพื่อให้ผู้รับผิดชอบพิจารณาและดำเนินการตามสิทธิของท่านต่อไป</i>

                                        <FormControlLabel onChange={handleChangeDataCheck} name="OwnDataNeedToAccess" control={<Checkbox />} label="เจ้าของข้อมูลประสงค์จะขอเข้าข้อมูลส่วนบุคคลเพื่อ" />
                                        <TextFieldCus
                                            placeholder='ระบุ'
                                            name={'OwnDataNeedToAccess_Note'}
                                            value={rowData.OwnDataNeedToAccess_Note || ''}
                                            onChange={(e) => handleChangeData(e)}
                                        />
                                        <FormControlLabel onChange={handleChangeDataCheck} name="OwnDataNeedToCopy" control={<Checkbox />} label="เจ้าของข้อมูลประสงค์จะขอรับสำเนาข้อมูลส่วนบุคคลเพื่อ" />
                                        <TextFieldCus
                                            placeholder='ระบุ'
                                            name={'OwnDataNeedToCopy_Note'}
                                            value={rowData.OwnDataNeedToCopy_Note || ''}
                                            onChange={(e) => handleChangeData(e)}
                                        />
                                        <FormControlLabel onChange={handleChangeDataCheck} name="OwnDataNotConsent" control={<Checkbox />} label="เจ้าของข้อมูลประสงค์จะขอให้เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ไม่ได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล เพื่อ" />
                                        <TextFieldCus
                                            placeholder='ระบุ'
                                            name={'OwnDataNotConsent_Note'}
                                            value={rowData.OwnDataNotConsent_Note || ''}
                                            onChange={(e) => handleChangeData(e)}
                                        />
                                        <FormControlLabel onChange={handleChangeDataCheck} name="OwnData_Expired" control={<Checkbox />} label="ข้อมูลส่วนบุคคลของเจ้าของข้อมูลหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ในการประมวลผลที่บริษัทได้แจ้งไว้" />
                                        <FormControlLabel onChange={handleChangeDataCheck} name="OwnData_ConsentWithdrawal" control={<Checkbox />} label="เจ้าของข้อมูลถอนความยินยอมในการประมวลผล และบริษัทฯ ไม่มีอ่านาจในการประมวลด้วยฐานอื่นที่ชอบด้วยกฎหมายอีกต่อไป" />
                                        <FormControlLabel onChange={handleChangeDataCheck} name="OwnData_ObjectionToProcess" control={<Checkbox />} label="เจ้าของข้อมูลส่วนบุคคลทำการค้ดค้านการประมวลผ โดยบริษัทไม่สามารถอ้างอิงยินยอมในการให้เก็บรวบรวมข้อมูลได้" />
                                        <FormControlLabel onChange={handleChangeDataCheck} name="OwnData_ObjectionByDirectMarketing" control={<Checkbox />} label="เจ้าของข้อมูลส่วนบุคคลทำการค้ดค้านการประมวลผลที่มีลักษณะเพี่อวัตถุประสงค์ กี่ยวกับการตลาดแบบตรง" />
                                        <FormControlLabel onChange={handleChangeDataCheck} name="Owndata_Illegitimate" control={<Checkbox />} label="ข้อมูลส่วนบุคคลถูกประมวลผลโดยไม่ชอบด้วยกฎหมาย" />
                                        <FormControlLabel onChange={handleChangeDataCheck} name="OwnData_CompanyMustDeletePersonalData" control={<Checkbox />} label="บริษัทฯ มีหน้าที่ต้องลบข้อมูลส่วนบุคคลดังกล่าว เพื่อให้เป็นไปตามการปฏิบัติตามกฎหมาย [โปรดระบุ]" />
                                        <TextFieldCus
                                            placeholder='ระบุ'
                                            name={'OwnData_CompanyMustDeletePersonalData_Note'}
                                            value={rowData.OwnData_CompanyMustDeletePersonalData_Note || ''}
                                            onChange={(e) => handleChangeData(e)}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>




                        </Grid>
                        <Stack
                            spacing={1}
                            direction="row"
                            sx={{ display: "flex", justifyContent: "end" }}
                        >
                            <Button variant="contained" color="success" sx={{ color: 'white' }} startIcon={<SaveIcon />}
                                onClick={handleSubmit(addDataSubjectRight)}>
                                ยืนยันตัวตน และ บันทึกข้อมูล
                            </Button>

                        </Stack>
                    </Box>
                </Box>
            </Paper>
            <Snackbar values={openSnackbar} setValues={setOpenSnackbar} />

        </>
    );
}

export default DataSubjectURL;
