import { IconFileText } from '@tabler/icons';

import FolderSharedIcon from '@mui/icons-material/FolderShared';

const AssuranceAndGapManagementMenu = {
    id: 'Assurance And Gap Management',
    title: 'Assurance And Gap Management',
    type: 'collapse',
    icon: FolderSharedIcon,
    children: [
        {
            id: 'Audit and gap plan',
            title: 'Audit and gap plan',
            type: 'item',
            url: '/assurance-and-gap-management/audit-and-gap-plan',
            breadcrumbs: false,
            icon: IconFileText
        },
        
        {
            id: 'Audit and gap program',
            title: ' Audit and gap program',
            type: 'item',
            url: '/assurance-and-gap-management/audit-and-gap-program',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Audit and gap schedules',
            title: ' Audit and gap schedules',
            type: 'item',
            url: '/assurance-and-gap-management/audit-and-gap-schedules',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Audit and gap ncr report',
            title: ' Audit and gap ncr report',
            type: 'item',
            url: '/assurance-and-gap-management/audit-and-gap-ncr-report',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Audit and gap summary report',
            title: ' Audit and gap summary report',
            type: 'item',
            url: '/assurance-and-gap-management/audit-and-gap-summary-report',
            breadcrumbs: false,
            icon: IconFileText
        },
    ]
}

export default AssuranceAndGapManagementMenu