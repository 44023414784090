import { IconFileText } from '@tabler/icons';

import FolderSharedIcon from '@mui/icons-material/FolderShared';

const DPIARiskAssessmentMenu = {
    id: 'DPIA & Risk Assessment',
    title: 'DPIA & Risk Assessment',
    type: 'collapse',
    icon: FolderSharedIcon,
    children: [
        {
            id: 'DPIA & Risk Assessment',
            title: 'DPIA & Risk Assessment',
            type: 'item',
            url: '/plan/asset-plan',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'RISK treatment plan',
            title: 'RISK treatment plan',
            type: 'item',
            url: '/plan/risk-treatment-plan',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Heat map',
            title: 'Heat map',
            type: 'item',
            url: '/report/heat-map',
            breadcrumbs: false,
            icon: IconFileText
        },
        {
            id: 'Report risk treatment plan',
            title: 'Report risk treatment plan',
            type: 'item',
            url: '/report/risk-treatment-plan',
            breadcrumbs: false,
            icon: IconFileText
        },
    ]
}

export default DPIARiskAssessmentMenu