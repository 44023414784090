import { useRoutes } from 'react-router-dom';
// routes
import Routes from './routes';
import config from '../config';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes(Routes(), config.basename);
}
