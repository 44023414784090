import React, { useState, useEffect } from "react";
import { Grid, Typography, Stack, Switch, Checkbox, FormGroup, FormControlLabel, Button, ImageList, ImageListItem, Avatar } from '@mui/material';
import axios from 'axios';
import TextFieldCus from '../../../components/form/TextFieldCus';
import { ClientJS } from 'clientjs';
import Snackbar from '../../../utils/Snackbar';
import { useNavigate } from 'react-router';


function ConsentPagePreviewById() {
    let clientJs = new ClientJS()
    const navigate = useNavigate()
    const hostname = process.env.REACT_APP_API
    const CollectionPointID = (new URLSearchParams(window.location.search)).get('CollectionPointID');
    const CompanyID = (new URLSearchParams(window.location.search)).get('CompanyID');
    const WebsiteID = (new URLSearchParams(window.location.search)).get('WebsiteID');
    const ConsentID = (new URLSearchParams(window.location.search)).get('ConsentID');
    const Browser = clientJs.getBrowser()
    const [dataPreview, setDataPreview] = useState([]);
    const [status, setStatus] = useState({ status: false });
    const [checked, setChecked] = useState(false);
    const [collectionPointList, setCollectionPointList] = useState([])
    const [rowData, setRowData] = useState({})
    const [openSnackbar, setOpenSnackbar] = useState({ status: false, type: "", msg: "" });
    const [dataConsent, setDataConsent] = useState([])

    const handleChange = (event) => {
        setStatus({
            ...status,
            [event.target.name]: event.target.checked,
        });
    };
    const handleUpdate = (e) => {
        setRowData({ ...rowData, [e.target.name]: e.target.value })
    }

    const handleChangeAcc = (event) => {
        setChecked(event.target.checked);
    };

    const getConsentPage = async () => {
        try {
            let res = await axios({
                url: `${hostname}/consent/getConsentPage`,
                method: "POST",
                headers: {
                    checkDevice: false
                },
                data: {
                    CollectionPointID: CollectionPointID,
                    CompanyID: CompanyID
                }
            })
            if (res.data.status === "success") {
                setDataPreview(res.data.data[0])
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getCollectionPointItemList = async () => {
        try {
            let res = await axios({
                url: `${hostname}/consent/getCollectionPointItemList`,
                method: "POST",
                headers: {
                    checkDevice: false
                },
                data: {
                    CollectionPointID: CollectionPointID,
                    CompanyID: CompanyID,
                }
            })
            if (res.data.status === "success") {
                setCollectionPointList(res.data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getGeneralConsentByID = async () => {

        try {
            let res = await axios({
                url: `${hostname}/consent/getGeneralConsentByID`,
                method: "POST",
                headers: {
                    checkDevice: false
                },
                data: {
                    "ConsentID": ConsentID
                }
            })
            console.log(`res.data`, res.data)
            if (res.data.status === "success" || res.data.status === "success-transaction") {
                // setOpenSnackbar({ status: true, type: "success", msg: "บันทึกข้อมูลสำเร็จ" });
                setDataConsent(res.data.data[0])
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getAllAccessToken = async () => {
        try {
            let res = await axios({
                url: `${hostname}/user/getAllAccessToken`,
                method: "POST",
                data: {},
                headers: {
                    checkDevice: false
                },
            })
            if (res.data.status === "success") {
                return res.data.data
            }
        } catch (error) {
            console.log(error);
        }
    }

    const checkAccessToken = async () => {
        const CompanyAccessToken = (new URLSearchParams(window.location.search)).get('CompanyID');
        const CollectionPointAccessToken = (new URLSearchParams(window.location.search)).get('CollectionPointID');
        const WebsiteAccessToken = (new URLSearchParams(window.location.search)).get('WebsiteID');
        const ConsentAccessToken = (new URLSearchParams(window.location.search)).get('ConsentID');

        let allAccessToken = await getAllAccessToken()
        let matchCompanyIDToken = allAccessToken.some(item => item.access_token === CompanyAccessToken)
        let matchCollectionPointIDToken = allAccessToken.some(item => item.access_token === CollectionPointAccessToken)
        let matchWebsiteIDToken = allAccessToken.some(item => item.access_token === WebsiteAccessToken)
        let matchConsentIDToken = allAccessToken.some(item => item.access_token === ConsentAccessToken)
        console.log(matchConsentIDToken)
        if (matchCompanyIDToken && matchCollectionPointIDToken && matchWebsiteIDToken && matchConsentIDToken) {
            return true
        }
    }

    useEffect(async () => {
        if (await checkAccessToken()) {
            getConsentPage()
            getCollectionPointItemList()
            getGeneralConsentByID()
        } else {
            navigate('/login')
        }
    }, [])

    return (
        <Grid sx={{ background: `${dataPreview.BodyBGColor}` }}>
            <Grid container>
                <Grid item xs={12} style={{ background: `${dataPreview.HeaderBGColor}`, height: 170 }}>
                    <Avatar src={dataPreview?.file?.HeaderLogo?.fileUrl} variant="square" sx={{ width: '150px', height: '150px' }} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={8} >
                    <Grid container xs={12} justifyContent="center" sx={{ marginTop: '3%' }}>
                        <Grid container xs={2} sx={{ marginTop: '1%' }}>
                            <Stack spacing={5} >
                                <Typography variant='subtitle1' whiteSpace='nowrap'>ID Card Number: *</Typography>
                                <Typography variant='subtitle1' whiteSpace='nowrap'>Email: * </Typography>
                                <Typography variant='subtitle1' whiteSpace='nowrap'>Name - Surname: *</Typography>
                                <Typography variant='subtitle1' whiteSpace='nowrap'>Tel: *</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={5}>
                            <Stack spacing={3} >

                                <TextFieldCus
                                    disabled={true}
                                    name={'CitizenID'}
                                    value={dataConsent.CitizenID || ''}
                                    onChange={(e) => handleUpdate(e)}
                                />
                                <TextFieldCus
                                    disabled={true}
                                    name={'Email'}
                                    value={dataConsent.Email}
                                    onChange={(e) => handleUpdate(e)}
                                />

                                <TextFieldCus
                                    disabled={true}
                                    name={'NameSurname'}
                                    value={dataConsent.NameSurname}
                                    onChange={(e) => handleUpdate(e)}
                                />
                                <TextFieldCus
                                    disabled={true}
                                    name={'Tel'}
                                    value={dataConsent.Tel}
                                    onChange={(e) => handleUpdate(e)}
                                />
                            </Stack>
                        </Grid>

                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid container item xs={10} style={{ justifyContent: 'center', marginTop: 2 }}>
                            <br />
                            <Typography style={{ color: `${dataPreview.BodyTopDerscriptionFontColor}` }}>{dataPreview.BodyTopDescription}</Typography>
                            <Grid item xs={10} container justifyContent="center">

                                {collectionPointList.map((item) => (
                                    < >
                                        <Switch color="success" defaultChecked={item.status_purpose} onChange={handleChange} name="status_purpose" />
                                        <Typography >{item.Code}</Typography>
                                    </>
                                ))}

                                {/* {status.status === true ?

                                    <Typography style={{ color: 'green', marginTop: '10px' }}>Active</Typography>
                                    : <Typography style={{ color: 'red', marginTop: '10px' }}>Inactive</Typography>} */}
                            </Grid>
                            <Typography style={{ color: `${dataPreview.BodyBottomDescriptionFontColor}` }}>{dataPreview.BodyBottomDescription}</Typography>
                            <FormGroup style={{ marginTop: '2%' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={"ConsentAccept"}
                                            checked={dataConsent.Status_Consent === true}
                                            onChange={(e) => handleChangeAcc(e)}
                                            name="consentPreview" />}
                                    label="ยอมรับคุกกี้เพื่อพัฒนาประสบการณ์ใช้งาน อ่านเพิ่มเติม ."
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar values={openSnackbar} setValues={setOpenSnackbar} />
        </Grid>
    )
}

export default ConsentPagePreviewById
