import { useState, useEffect, useContext } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, IconButton, Typography, Button, Box } from '@mui/material/'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { hostname } from '../../../../hostname';
import axios from 'axios'
import { UserContext } from '../../../../components/contexts/UserContext'
import logoutFunc from '../../../../utils/logout'

function SwitchCompanyDialog(props) {
    const { userDataContext } = useContext(UserContext)
    const userProfile = userDataContext?.userProfile
    const { setOpen, open, userCompany } = props;
    const [defaultCompanyValue, setDefaultCompanyValue] = useState()

    const handleChangeCompany = (event) => {
        setDefaultCompanyValue(event.target.value)
    }

    const onClickSwitchCompany = async () => {
        try {
            let res = await axios({
                method: "post",
                url: `${hostname}/user/switchCompany`,
                data: {
                    CompanyID: defaultCompanyValue
                },
            });
            if (res.data.status === "success") {
                setOpen(false)
                await logoutFunc.logout()
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onCloseDialog = () => {
        setOpen(false)
        setDefaultCompanyValue(userProfile?.CompanyID)
    }

    useEffect(() => {
        setDefaultCompanyValue(userProfile?.CompanyID)
    }, [userProfile])

    return (
        <>
            <Dialog
                open={open}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="dialogTitle">Switch Company</Typography>
                    <IconButton
                        disableRipple
                        onClick={onCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText >
                        <Stack pt={1} pb={2} spacing={2}>
                            <Stack>
                                <FormControl>
                                    <RadioGroup
                                        value={defaultCompanyValue}
                                        onChange={handleChangeCompany}
                                    >
                                        {userCompany.map((company_item) => (
                                            <FormControlLabel value={company_item.CompanyID} control={<Radio />} label={company_item.Name} sx={{ color: "black" }} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="center" alignItems="center" >
                                <Button variant="contained" startIcon={<SwapHorizIcon />} onClick={onClickSwitchCompany} disabled={userCompany.length === 1}>Switch</Button>
                            </Stack>
                        </Stack>
                    </DialogContentText>
                </DialogContent >
            </Dialog >
        </>
    )
}

export default SwitchCompanyDialog