import {IconCalendarMinus,IconTemplate } from '@tabler/icons';

const icons = {
    IconTemplate,
    IconCalendarMinus
};

const TemplateMenu = 
    {
        id: 'Template',
        title: 'Template',
        type: 'collapse',
        icon: icons.IconTemplate,
        children: [
            {
                id: 'Audit Checklist Template',
                title: 'Audit Checklist Template',
                type: 'item',
                url: '/template/audit-checklist',
                breadcrumbs: false,
                icon: icons.IconCalendarMinus,

            },
            {
                id: 'Audit Matrix Template',
                title: 'Audit Matrix Template',
                type: 'item',
                url: '/template/audit-matrix',
                breadcrumbs: false,
                icon: icons.IconCalendarMinus,
            },
        ]
    }

export default TemplateMenu
