import { useState } from 'react'
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function SearchBar({ menuListRef }) {
    const [searchValue, setSearchValue] = useState('')

    const onSearchChange = (e) => {
        setSearchValue(e.target.value)
        menuListRef.current.searchMenuItem(e.target.value)
    }

    const onCancelSearch = () => {
        setSearchValue("")
        menuListRef.current.searchMenuItem("")
    }
    return (
        <></>
        // <OutlinedInput
        //     size="small"
        //     placeholder="ค้นหา"
        //     fullWidth
        //     value={searchValue}
        //     onChange={onSearchChange}
        //     endAdornment={
        //         <InputAdornment position="end">
        //             <IconButton edge="end" onClick={onCancelSearch} disabled={!searchValue}>
        //                 {!searchValue ? (
        //                     <SearchIcon />
        //                 ) : (
        //                     <CloseIcon />
        //                 )}
        //             </IconButton>
        //         </InputAdornment>
        //     }
        // />
    )
}

export default SearchBar