import {IconCheckupList , IconFolders} from '@tabler/icons';

const icons = {
    IconCheckupList,
    IconFolders
};

const PolicyMenu = 
    {
        id: 'Policy And Notices Management',
        title: 'Policy And Notices Management',
        type: 'collapse',
        icon: icons.IconCheckupList,
        children: [
            {
                id: 'Policy And Notices',
                title: 'Policy And Notices',
                type: 'item',
                url: '/policy-management/policy-and-notices',
                icon: icons.IconFolders,
                breadcrumbs: false,
               
            },
          
 
        ]
    }

export default PolicyMenu
