import Cookies from 'universal-cookie';
const cookies = new Cookies()

const logout = async () => {
    try {
        cookies.remove('isLoggedIn')
        window.location.href = "/login";
    } catch (error) {
        console.log(error);
    }
}

export default { logout }