import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
    Avatar, Box, Chip, ClickAwayListener, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText,
    Paper, Popper, Stack, Typography, Collapse, IconButton
} from '@mui/material';
import MainCard from '../../../../components/cards/MainCard';
import Transitions from '../../../../components/extended/Transitions';
import {
    IconLogout, IconSwitchHorizontal, IconSettings, IconDeviceDesktop
} from '@tabler/icons';
import axios from 'axios'
import { hostname } from '../../../../hostname';
import DeviceManagerDialog from '../../../../components/login/DeviceManagerDialog';
import SwitchCompanyDialog from './SwitchCompanyDialog';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BusinessIcon from '@mui/icons-material/Business';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Flag from 'react-flagkit';
import SettingsIcon from '@mui/icons-material/Settings';
import signedURLFunc from '../../../../utils/signedUrl'
import { UserContext } from '../../../../components/contexts/UserContext'
import logoutFunc from '../../../../utils/logout'

const ProfileSection = () => {
    const { userDataContext } = useContext(UserContext)
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const userProfile = userDataContext?.userProfile
    const [openDeviceManagerDialog, setOpenDeviceManagerDialog] = useState(false)
    const [deviceData, setDeviceData] = useState([])
    const [userCompany, setUserCompany] = useState([])

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [openSwitchCompanyDialog, setOpenSwitchCompanyDialog] = useState(false);
    const [openAdminMenu, setOpenAdminMenu] = useState(false);

    const [profileImage, setProfileImage] = useState();

    const anchorRef = useRef(null);
    const handleLogout = async () => {
        try {
            let res = await axios({
                method: "post",
                url: `${hostname}/auth/logout`,
            });
            if (res.data.status === 'success') {
                window.location.href = "/login";
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const getDevice = async (event) => {
        try {
            handleClose(event);
            let res = await axios({
                method: "post",
                url: `${hostname}/auth/getDevice`,
                headers: {
                    checkDevice: false
                },
                data: {
                    "UserID": userProfile?.UserID
                },
            });
            if (res.data.status === "success") {
                setOpenDeviceManagerDialog(true)
                setDeviceData(res.data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const switchCompany = async (event) => {
        handleClose(event);
        getUserCompany()
        setOpenSwitchCompanyDialog(true)
    }

    const onClickChangeLanguage = async (event, language) => {
        handleClose(event);
        changeLanguage(language)
    }

    const getUserCompany = async () => {
        try {
            let res = await axios({
                method: "post",
                url: `${hostname}/user/getUserCompany`,
                data: {}
            });
            if (res.data.status === "success") {
                setUserCompany(res.data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const changeLanguage = async (languageCode) => {
        try {
            let res = await axios({
                method: "post",
                url: `${hostname}/user/changeLanguage`,
                data: { languageCode: languageCode }
            });
            if (res.data.status === "success") {
                window.location.reload();
            }
        } catch (error) {
            console.log(error);
        }
    }


    const handleClickOpenAdminMenu = () => {
        setOpenAdminMenu(!openAdminMenu);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(async () => {
        if (userProfile !== undefined && userProfile?.ProfileImagePath !== null) {
            let signedURL = await signedURLFunc.getSignedURL(userProfile?.ProfileImagePath)
            setProfileImage(signedURL)
        }
    }, [userProfile])

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={profileImage}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                        {`${userProfile?.FirstName} ${userProfile?.LastName}`}
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="subtitle2">{userProfile?.Position}</Typography>
                                            </Stack>
                                            <Stack alignItems="center" justifyContent="center">
                                                <Stack direction="row" spacing={1}>
                                                    <IconButton sx={{ p: '3px', border: userProfile?.Language === "EN" && '1px ridge' }} onClick={(event) => onClickChangeLanguage(event, 'EN')}>
                                                        <Flag country="US" />
                                                    </IconButton>
                                                    <IconButton sx={{ p: '3px', border: userProfile?.Language === "TH" && '1px solid' }} onClick={(event) => onClickChangeLanguage(event, 'TH')}>
                                                        <Flag country="TH" />
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Divider sx={{ pt: 2 }} />
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            {(userProfile?.Role.includes("super admin") || userProfile?.Role.includes("admin") || userProfile?.Role.includes("it")) &&
                                                <>
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        onClick={handleClickOpenAdminMenu}
                                                    >
                                                        <ListItemIcon>
                                                            <AdminPanelSettingsIcon stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Admin Manager" />
                                                        {openAdminMenu ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItemButton>
                                                    <Collapse in={openAdminMenu} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding>
                                                            {!userProfile?.Role.includes("it") &&
                                                                <ListItemButton
                                                                    sx={{ pl: 4, borderRadius: `${customization.borderRadius}px` }}
                                                                    selected={selectedIndex === 11}
                                                                    onClick={(event) => handleListItemClick(event, 11, userProfile?.Role.includes("super admin") ? '/super_admin/manage_organization' : '/admin/manage_organization')}
                                                                >
                                                                    <ListItemIcon>
                                                                        <BusinessIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary="Manage Organization" />
                                                                </ListItemButton>
                                                            }
                                                            <ListItemButton
                                                                sx={{ pl: 4, borderRadius: `${customization.borderRadius}px` }}
                                                                selected={selectedIndex === 22}
                                                                onClick={(event) => handleListItemClick(event, 22, userProfile?.Role.includes("super admin") ? '/super_admin/manage_user' : userProfile?.Role.includes("admin") ? '/admin/manage_user' : '/it/manage_user')}
                                                            >
                                                                <ListItemIcon>
                                                                    <SupervisedUserCircleIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary="Manage User" />
                                                            </ListItemButton>
                                                            {!userProfile?.Role.includes("it") &&
                                                                <ListItemButton
                                                                    sx={{ pl: 4, borderRadius: `${customization.borderRadius}px` }}
                                                                    selected={selectedIndex === 33}
                                                                    onClick={(event) => handleListItemClick(event, 33, userProfile?.Role.includes("super admin") ? '/super_admin/manage_role_permission' : '/admin/manage_role_permission')}
                                                                >
                                                                    <ListItemIcon>
                                                                        <VerifiedUserIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary="Manage Role & Permission" />
                                                                </ListItemButton>
                                                            }
                                                            {!userProfile?.Role.includes("it") &&
                                                                <ListItemButton
                                                                    sx={{ pl: 4, borderRadius: `${customization.borderRadius}px` }}
                                                                    selected={selectedIndex === 44}
                                                                    onClick={(event) => handleListItemClick(event, 44, userProfile?.Role.includes("super admin") ? '/super_admin/all_devices_manager' : '/admin/all_devices_manager')}
                                                                >
                                                                    <ListItemIcon>
                                                                        <IconDeviceDesktop />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary="All Devices Manager" />
                                                                </ListItemButton>
                                                            }
                                                            {!userProfile?.Role.includes("it") &&
                                                                <ListItemButton
                                                                    sx={{ pl: 4, borderRadius: `${customization.borderRadius}px` }}
                                                                    selected={selectedIndex === 55}
                                                                    onClick={(event) => handleListItemClick(event, 55, userProfile?.Role.includes("super admin") ? '/super_admin/system_setting' : '/admin/system_setting')}
                                                                >
                                                                    <ListItemIcon>
                                                                        <SettingsIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary="System Setting" />
                                                                </ListItemButton>
                                                            }
                                                        </List>
                                                    </Collapse>
                                                </>
                                            }
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 66}
                                                onClick={(event) => switchCompany(event)}
                                            >
                                                <ListItemIcon>
                                                    <IconSwitchHorizontal stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary="Switch Company" />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 77}
                                                onClick={(event) => getDevice(event)}
                                            >
                                                <ListItemIcon>
                                                    <IconDeviceDesktop stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary="Device Manager" />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 88}
                                                onClick={(event) => handleListItemClick(event, 88, '/account-setting')}
                                            >
                                                <ListItemIcon>
                                                    <IconSettings stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary="Account Settings" />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 99}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary="Logout" />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions >
                )}
            </Popper >

            <SwitchCompanyDialog open={openSwitchCompanyDialog} setOpen={setOpenSwitchCompanyDialog} userCompany={userCompany} />
            <DeviceManagerDialog open={openDeviceManagerDialog} setOpen={setOpenDeviceManagerDialog} deviceData={deviceData} getDevice={getDevice} />
        </>
    );
};

export default ProfileSection;
