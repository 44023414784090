import { IconTypography, IconPalette, IconShadow, IconWindmill ,IconClipboard,IconClipboardList,IconChartPie } from '@tabler/icons';

const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconClipboard,
    IconClipboardList,
    IconChartPie
};

const ConsentMenu = 
    {
        id: 'Consent Management',
        title: 'Consent Management',
        type: 'collapse',
        icon: icons.IconClipboard,
        children: [
            {
                id: 'General Consent',
                title: 'General Consent',
                type: 'item',
                url: '/consent/general-consent',
                icon: icons.IconClipboardList,
                breadcrumbs: false,
               
            },
            {
                id: 'Cookie Consent',
                title: 'Cookie Consent',
                type: 'item',
                url: '/consent/cookie-consent',
                icon: icons.IconClipboardList,
                breadcrumbs: false
            },
            {
              id: 'Collection Point',
              title: 'Collection Point',
              type: 'item',
              url: '/consent/collection-point',
              icon: icons.IconClipboardList,
              breadcrumbs: false
          },
          {
            id: 'Consent Report',
            title: 'Consent Report',
            type: 'item',
            url: '/consent/consent-report',
            icon: icons.IconChartPie,
            breadcrumbs: false
        },
        ]
    }

export default ConsentMenu
