import { TextField } from '@mui/material'
import React from 'react'

function TextFieldCus(props) {
    const { name, value, required, error, multiline, placeholder, type, disabled, readOnly, helperText } = props
    return (
        <TextField
            fullWidth
            required={required}
            error={error}
            size='small'
            type={type}
            multiline={multiline}
            placeholder={placeholder}
            rows={3}
            name={name}
            value={value}
            inputProps={{ readOnly: readOnly }}
            disabled={disabled}
            onChange={(e) => props.onChange(e)}
            helperText={helperText}
        />
    )
}

export default TextFieldCus