import { IconCalendarEvent, IconCalendarMinus,IconChartPie } from '@tabler/icons';

const icons = {
    IconCalendarEvent,
    IconCalendarMinus,
    IconChartPie
};

const ExecutiveMenu = 
    {
        id: 'Executive Support',
        title: 'Executive Support',
        type: 'collapse',
        icon: icons.IconCalendarEvent,
        children: [
            {
                id: 'Task',
                title: 'Task',
                type: 'item',
                url: '/executive-support/task',
                icon: icons.IconCalendarMinus,
                breadcrumbs: false,
               
            },
            {
                id: 'Summary All Task',
                title: 'Summary All Task',
                type: 'item',
                url: '/executive-support/summary-all-task',
                icon: icons.IconChartPie,
                breadcrumbs: false
            },
            {
              id: 'Task Detail',
              title: 'Task Detail',
              type: 'item',
              url: '/executive-support/task-detail',
              icon: icons.IconCalendarMinus,
              breadcrumbs: false
          },
         
        ]
    }

export default ExecutiveMenu
