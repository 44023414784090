import React from 'react'
import { Dialog, DialogContent, Typography, Stack, Button, IconButton, Grid, Box } from '@mui/material';
import { Close } from '@mui/icons-material';

function ConfirmDialog(props) {
    const { open, msg } = props

    return (
        <>
            <Dialog open={open}>
                <Box display="flex" justifyContent="flex-end">
                    <IconButton
                        onClick={() => { props.onclose(false) }}
                    >
                        <Close />
                    </IconButton>
                </Box>
                <DialogContent>
                    <Grid item xs={12}>
                        <Stack spacing={3} p={3} pt={0}>
                            <Typography variant='h5'>{msg}</Typography>
                            <Stack direction='row' justifyContent='space-evenly' spacing={1}>
                                <Button
                                    variant='outlined'
                                    color='success'
                                    onClick={() => {
                                        props.confirm(true)
                                        props.onclose(false)
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant='outlined'
                                    color='error'
                                    onClick={() => { props.onclose(false) }}
                                >
                                    No
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ConfirmDialog