import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import MinimalLayout from '../layout/MinimalLayout';
import Loadable from '../components/Loadable';
import DataSubjectURL from '../views/data-subject-right/DataSubjectURL';
import ConsentPagePreview from '../views/consent/collection-point/ConsentPagePreview'
import ConsentPageSetting from '../views/consent/cookie-consent/ConsentPageSetting';
import ConsentPagePreviewById from '../views/consent/Transaction/ConsentPagePreviewById';
import NotAccessPage from '../utils/NotAcessPage';
import Cookies from 'universal-cookie';

const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

const Home = Loadable(lazy(() => import('../views/home/Home')));
const ResetPassword = Loadable(lazy(() => import('../views/user/ResetPassword')));
const PdInventory = Loadable(lazy(() => import('../views/personal-data-management/PdInventory')));
const AddPersonalDataInventory = Loadable(lazy(() => import('../views/personal-data-management/AddPersonalDataInventory')));
const LawFulBasis = Loadable(lazy(() => import('../views/personal-data-management/LawFulBasis')));
const DataFlow = Loadable(lazy(() => import('../views/personal-data-management/DataFlow')));
const RightToAccess = Loadable(lazy(() => import('../views/master-data/RightToAccess')));
const Login = Loadable(lazy(() => import('../views/login/Login')));
const TaskProject = Loadable(lazy(() => import('../views/master-data/TaskProject')));
const RiskResponseOptions = Loadable(lazy(() => import('../views/master-data/RiskResponseOptions')));
const Impact = Loadable(lazy(() => import('../views/master-data/Impact')));
const Title = Loadable(lazy(() => import('../views/master-data/Title')));
const Position = Loadable(lazy(() => import('../views/master-data/Position')));
const Department = Loadable(lazy(() => import('../views/master-data/Department')));
const Agency = Loadable(lazy(() => import('../views/master-data/Agency')));
const AssetDataType = Loadable(lazy(() => import('../views/master-data/AssetDataType')));
const BusinessFunction = Loadable(lazy(() => import('../views/master-data/BusinessFunction')));
const CategoryOfIndividual = Loadable(lazy(() => import('../views/master-data/CategoryOfIndividual')));
const CategoryOfPersonalData = Loadable(lazy(() => import('../views/master-data/CategoryOfPersonalData')));
const DataBreachStatus = Loadable(lazy(() => import('../views/master-data/DataBreachStatus')));
const ImpactType = Loadable(lazy(() => import('../views/master-data/ImpactType')));
const LiaActivity = Loadable(lazy(() => import('../views/master-data/LiaActivity')));
const LiaLikelihood = Loadable(lazy(() => import('../views/master-data/LiaLikelihood')));
const LiaPersonalDataSubject = Loadable(lazy(() => import('../views/master-data/LiaPersonalDataSubject')));
const LiaPriorityCategory = Loadable(lazy(() => import('../views/master-data/LiaPriorityCategory')));
const LiaRuleAndNotices = Loadable(lazy(() => import('../views/master-data/LiaRuleAndNotices')));
const Likelihood = Loadable(lazy(() => import('../views/master-data/Likelihood')));
const Location = Loadable(lazy(() => import('../views/master-data/Location')));
const LocationOfPersonalData = Loadable(lazy(() => import('../views/master-data/LocationOfPersonalData')));
const MeasuresToMaintainSafety = Loadable(lazy(() => import('../views/master-data/MeasuresToMaintainSafety')));
const NoticeOwnerDataStatus = Loadable(lazy(() => import('../views/master-data/NoticeOwnerDataStatus')));
const PersonalDataToKeep = Loadable(lazy(() => import('../views/master-data/PersonalDataToKeep')));
const RejectRequest = Loadable(lazy(() => import('../views/master-data/RejectRequest')));
const RightToDataPortability = Loadable(lazy(() => import('../views/master-data/RightToDataPortability')));
const RightToObject = Loadable(lazy(() => import('../views/master-data/RightToObject')));
const RightToRectification = Loadable(lazy(() => import('../views/master-data/RightToRectification')));
const RiskAcceptance = Loadable(lazy(() => import('../views/master-data/RiskAcceptance')));
const SourceOfPersonalData = Loadable(lazy(() => import('../views/master-data/SourceOfPersonalData')));
const Threat = Loadable(lazy(() => import('../views/master-data/Threat')));
const ThreatType = Loadable(lazy(() => import('../views/master-data/ThreatType')));
const TimeToKeep = Loadable(lazy(() => import('../views/master-data/TimeToKeep')));
const TypeOfReject = Loadable(lazy(() => import('../views/master-data/TypeOfReject')));
const TypeOfRequest = Loadable(lazy(() => import('../views/master-data/TypeOfRequest')));
const Valuation = Loadable(lazy(() => import('../views/master-data/Valuation')));
const ValuationType = Loadable(lazy(() => import('../views/master-data/ValuationType')));
const Vulnerability = Loadable(lazy(() => import('../views/master-data/Vulnerability')));
const Website = Loadable(lazy(() => import('../views/master-data/Website')));
const Purpose = Loadable(lazy(() => import('../views/master-data/Purpose')));
const DataController = Loadable(lazy(() => import('../views/master-data/DataController')));
const DataProtectionOfficer = Loadable(lazy(() => import('../views/master-data/DataProtectionOfficer')));
const ProcessingAgreement = Loadable(lazy(() => import('../views/master-data/ProcessingAgreement')));
const ManageOrganization = Loadable(lazy(() => import('../views/admin/ManageOrganization')))
const ManageUser = Loadable(lazy(() => import('../views/admin/ManageUser')))
const ManageRolePermission = Loadable(lazy(() => import('../views/admin/ManageRolePermission')))
const AllDevicesManager = Loadable(lazy(() => import('../views/admin/AllDevicesManager')))
const SystemSetting = Loadable(lazy(() => import('../views/admin/SystemSetting')))
const AssetPlan = Loadable(lazy(() => import('../views/dpia&risk-assessment/AssetPlan')))
const AddAssetPlan = Loadable(lazy(() => import('../views/dpia&risk-assessment/AddAssetPlan')))
const AssetPlanCriteria = Loadable(lazy(() => import('../views/dpia&risk-assessment/AssetPlanCriteria')))
const RiskProfile = Loadable(lazy(() => import('../views/dpia&risk-assessment/RiskProfile')))
const AddRiskProfile = Loadable(lazy(() => import('../views/dpia&risk-assessment/AddRiskProfile')))
const RiskTreatmentPlan = Loadable(lazy(() => import('../views/dpia&risk-assessment/RiskTreatmentPlan')))
const HeatMap = Loadable(lazy(() => import('../views/dpia&risk-assessment/HeatMap')))
const ReportRiskTreatmentPlan = Loadable(lazy(() => import('../views/dpia&risk-assessment/ReportRiskTreatmentPlan')))
const AccountSetting = Loadable(lazy(() => import('../views/account-setting/AccountSetting')));
const CookieConsent = Loadable(lazy(() => import('../views/consent/cookie-consent/CookieConsent')));
const CookieConsentHistory = Loadable(lazy(() => import('../views/consent/cookie-consent-history/CookieConsentHistory')));
const AddCookieConsent = Loadable(lazy(() => import('../views/consent/cookie-consent/AddCookieConsent')));
const CollectionPoint = Loadable(lazy(() => import('../views/consent/collection-point/CollectionPoint')));
const AddCollectionPoint = Loadable(lazy(() => import('../views/consent/collection-point/AddCollectionPoint')));
const EditCollectionPoint = Loadable(lazy(() => import('../views/consent/collection-point/EditCollectionPoint')));
const EditConsentCookie = Loadable(lazy(() => import('../views/consent/cookie-consent/EditConsentCookie')));



const ConsentReport = Loadable(lazy(() => import('../views/consent/ConsentReport')));
const GeneralConsent = Loadable(lazy(() => import('../views/consent/GeneralConsent')))
const TransactionConsent = Loadable(lazy(() => import('../views/consent/Transaction/TransactionConsent')))
const PolicyAndNotices = Loadable(lazy(() => import('../views/policy-and-notices/PolicyAndNotices')))
const DataBreach = Loadable(lazy(() => import('../views/data-breach/DataBreach')))
const DataAnalysis = Loadable(lazy(() => import('../views/data-discover/DataAnalysis')))
const Legitimated = Loadable(lazy(() => import('../views/lia-management/Legitimated')))
const Task = Loadable(lazy(() => import('../views/executive-support/Task')))
const TaskDetail = Loadable(lazy(() => import('../views/executive-support/TaskDetail')))
const SummaryAllTask = Loadable(lazy(() => import('../views/executive-support/Summary')))
const AuditCheckList = Loadable(lazy(() => import('../views/template/AuditCheckList')))
const AuditMatrix = Loadable(lazy(() => import('../views/template/AuditMatrix')))
const AddPolicyAndNotices = Loadable(lazy(() => import('../views/policy-and-notices/AddPolicyAndNotices')))
const EditPolicyAndNotices = Loadable(lazy(() => import('../views/policy-and-notices/EditPolicyAndNotices')))
const AddDataAnalysis = Loadable(lazy(() => import('../views/data-discover/AddDataAnalysis')))
const SearchDataAnalysis = Loadable(lazy(() => import('../views/data-discover/SearchDataAnalysis')))
const AddLegitimated = Loadable(lazy(() => import('../views/lia-management/AddLegitimated')))
const DataSubjectForm = Loadable(lazy(() => import('../views/data-subject-right/DataSubjectForm')))
const DataSubjectRight = Loadable(lazy(() => import('../views/data-subject-right/DataSubjectRight')))
const ReportDataSubjectRight = Loadable(lazy(() => import('../views/data-subject-right/ReportDataSubjectRight')))
const AuditAndGapPlan = Loadable(lazy(() => import('../views/assurance-and-gap-management/AuditAndGapPlan')))
const AuditAndGapProgram = Loadable(lazy(() => import('../views/assurance-and-gap-management/AuditAndGapProgram')))
const AuditAndGapProgramByID = Loadable(lazy(() => import('../views/assurance-and-gap-management/AuditAndGapProgramByID')))
const AuditAndGapSchedules = Loadable(lazy(() => import('../views/assurance-and-gap-management/AuditAndGapSchedules')))
const AuditAndGapNcrReport = Loadable(lazy(() => import('../views/assurance-and-gap-management/AuditAndGapNcrReport')))
const AuditAndGapSummaryReport = Loadable(lazy(() => import('../views/assurance-and-gap-management/AuditAndGapSummaryReport')))
const AddAuditGapPlan = Loadable(lazy(() => import('../views/assurance-and-gap-management/AddAuditGapPlan')))
const AssuranceAuditMatrix = Loadable(lazy(() => import('../views/assurance-and-gap-management/ActionAuditGapProgram/AuditMatrix')))
const AssuranceAuditCheckList = Loadable(lazy(() => import('../views/assurance-and-gap-management/ActionAuditGapProgram/AuditCheckList')))

const cookies = new Cookies()

const Routes = () => {
  let isLoggedIn = true

  if (cookies.get('isLoggedIn') !== 'true' || cookies.get('isLoggedIn') === undefined) {
    isLoggedIn = false
  }

  return [
    {
      path: '/',
      element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
      children: [
        //main
        {
          path: '/',
          element: <Home />,
        },
        //account-setting
        {
          path: '/account-setting',
          element: <AccountSetting />,
        },
        //admin
        {
          path: '/super_admin/manage_organization',
          element: <ManageOrganization />,
        },
        {
          path: '/admin/manage_organization',
          element: <ManageOrganization />,
        },
        {
          path: '/super_admin/manage_user',
          element: <ManageUser />,
        },
        {
          path: '/admin/manage_user',
          element: <ManageUser />,
        },
        {
          path: '/it/manage_user',
          element: <ManageUser />,
        },
        {
          path: '/super_admin/manage_role_permission',
          element: <ManageRolePermission />,
        },
        {
          path: '/admin/manage_role_permission',
          element: <ManageRolePermission />,
        },
        {
          path: '/super_admin/all_devices_manager',
          element: <AllDevicesManager />,
        },
        {
          path: '/admin/all_devices_manager',
          element: <AllDevicesManager />,
        },
        {
          path: '/super_admin/system_setting',
          element: <SystemSetting />,
        },
        {
          path: '/admin/system_setting',
          element: <SystemSetting />,
        },
        //utils
        {
          path: '/utils/util-typography',
          element: <UtilsTypography />,
        },
        {
          path: '/utils/util-color',
          element: <UtilsColor />,
        },
        {
          path: '/utils/util-shadow',
          element: <UtilsShadow />,
        },
        {
          path: '/icons/tabler-icons',
          element: <UtilsTablerIcons />,
        },
        {
          path: '/icons/material-icons',
          element: <UtilsMaterialIcons />,
        },
        // PD Inventory
        {
          path: '/pdm/pd-inventory',
          element: <PdInventory />
        },
        {
          path: '/pdm/add-pd-inventory',
          element: <AddPersonalDataInventory />
        },
        {
          path: '/pdm/update-pd-inventory',
          element: <AddPersonalDataInventory />
        },
        {
          path: '/pdm/lawful-basis',
          element: <LawFulBasis />
        },
        {
          path: '/pdm/data-flow',
          element: <DataFlow />
        },
        //dpia&risk assessment
        {
          path: '/plan/asset-plan',
          element: <AssetPlan />
        },
        {
          path: '/plan/add-asset-plan',
          element: <AddAssetPlan />
        },
        {
          path: '/plan/update-asset-plan',
          element: <AddAssetPlan />
        },
        {
          path: '/plan/asset-plan-criteria',
          element: <AssetPlanCriteria />
        },
        {
          path: '/risk/risk-profile',
          element: <RiskProfile />
        },
        {
          path: '/risk/add-risk-profile',
          element: <AddRiskProfile />
        },
        {
          path: '/plan/risk-treatment-plan',
          element: <RiskTreatmentPlan />
        },
        {
          path: '/report/heat-map',
          element: <HeatMap />
        },
        {
          path: '/report/risk-treatment-plan',
          element: <ReportRiskTreatmentPlan />
        },
        //Assurance and Gap Management
        {
          path: '/assurance-and-gap-management/audit-and-gap-plan',
          element: <AuditAndGapPlan />
        },
        {
          path: '/assurance-and-gap-management/audit-and-gap-program',
          element: <AuditAndGapProgram />
        },
        {
          path: '/assurance-and-gap-management/audit-and-gap-program-byid',
          element: <AuditAndGapProgramByID />
        },
        {
          path: '/assurance-and-gap-management/audit-and-gap-schedules',
          element: <AuditAndGapSchedules />
        },
        {
          path: '/assurance-and-gap-management/audit-and-gap-ncr-report',
          element: <AuditAndGapNcrReport />
        },
        {
          path: '/assurance-and-gap-management/audit-and-gap-summary-report',
          element: <AuditAndGapSummaryReport />
        },
        {
          path: '/assurance-and-gap-management/add-audit-and-gap-plan',
          element: <AddAuditGapPlan />
        },
        {
          path: '/assurance-and-gap-management/audit-matrix',
          element: <AssuranceAuditMatrix />
        },
        {
          path: '/assurance-and-gap-management/audit-checklist',
          element: <AssuranceAuditCheckList />
        },

        //master-data
        {
          path: '/master-data/task-project',
          element: <TaskProject />
        },
        {
          path: '/master-data/risk-response-options',
          element: <RiskResponseOptions />
        },
        {
          path: '/master-data/impact',
          element: <Impact />
        },
        {
          path: '/master-data/title',
          element: <Title />
        },
        {
          path: '/master-data/position',
          element: <Position />
        },

        {
          path: '/master-data/department',
          element: <Department />
        },
        {
          path: '/master-data/category-of-individual',
          element: <CategoryOfIndividual />
        },
        {
          path: '/master-data/category-of-personal-data',
          element: <CategoryOfPersonalData />
        },
        {
          path: '/master-data/location-of-personal-data',
          element: <LocationOfPersonalData />
        },
        {
          path: '/master-data/source-of-personal-data',
          element: <SourceOfPersonalData />
        },
        {
          path: '/master-data/purpose',
          element: <Purpose />
        },
        {
          path: '/master-data/website',
          element: <Website />
        },
        {
          path: '/master-data/data-controller',
          element: <DataController />
        },
        {
          path: '/master-data/data-protection-officer',
          element: <DataProtectionOfficer />
        },
        {
          path: '/master-data/business-function',
          element: <BusinessFunction />
        },
        {
          path: '/master-data/measures-to-maintain-safety',
          element: <MeasuresToMaintainSafety />
        },
        {
          path: '/master-data/personal-data-to-keep',
          element: <PersonalDataToKeep />
        },
        {
          path: '/master-data/time-to-keep',
          element: <TimeToKeep />
        },
        {
          path: '/master-data/right-to-access',
          element: <RightToAccess />
        },
        {
          path: '/master-data/right-to-data-portability',
          element: <RightToDataPortability />
        },
        {
          path: '/master-data/right-to-object',
          element: <RightToObject />
        },
        {
          path: '/master-data/right-to-rectification',
          element: <RightToRectification />
        },
        {
          path: '/master-data/processing-agreement',
          element: <ProcessingAgreement />
        },
        {
          path: '/master-data/agency',
          element: <Agency />
        },
        {
          path: '/master-data/notice-owner-data-status',
          element: <NoticeOwnerDataStatus />
        },
        {
          path: '/master-data/data-breach-status',
          element: <DataBreachStatus />
        },
        {
          path: '/master-data/valuation-type',
          element: <ValuationType />
        },
        {
          path: '/master-data/impact-type',
          element: <ImpactType />
        },
        {
          path: '/master-data/threat-type',
          element: <ThreatType />
        },
        {
          path: '/master-data/risk-acceptance',
          element: <RiskAcceptance />
        },
        {
          path: '/master-data/vulnerability',
          element: <Vulnerability />
        },
        {
          path: '/master-data/threat',
          element: <Threat />
        },
        {
          path: '/master-data/likelihood',
          element: <Likelihood />
        },
        {
          path: '/master-data/valuation',
          element: <Valuation />
        },
        {
          path: '/master-data/type-of-request',
          element: <TypeOfRequest />
        },
        {
          path: '/master-data/type-of-reject',
          element: <TypeOfReject />
        },
        {
          path: '/master-data/reject-request',
          element: <RejectRequest />
        },
        {
          path: '/master-data/location',
          element: <Location />
        },
        {
          path: '/master-data/asset-data-type',
          element: <AssetDataType />
        },
        {
          path: '/master-data/lia-activity',
          element: <LiaActivity />
        },
        {
          path: '/master-data/lia-likelihood',
          element: <LiaLikelihood />
        },
        {
          path: '/master-data/lia-personal-data-subject',
          element: <LiaPersonalDataSubject />
        },
        {
          path: '/master-data/lia-priority-category',
          element: <LiaPriorityCategory />
        },
        {
          path: '/master-data/lia-rule-and-notices',
          element: <LiaRuleAndNotices />
        },
        //consent

        {
          path: '/consent/cookie-consent',
          element: <CookieConsent />
        },
        {
          path: '/consent/cookie-consent-history',
          element: <CookieConsentHistory />
        },
        {
          path: '/consent/add-cookie-consent',
          element: <AddCookieConsent />
        },
        {
          path: '/consent/edit-cookie-consent',
          element: <EditConsentCookie />
        },
        {
          path: '/consent/setting-page',
          element: <ConsentPageSetting />
        },
        {
          path: '/consent/collection-point',
          element: <CollectionPoint />
        },

        {
          path: '/consent/add-collection-point',
          element: <AddCollectionPoint />
        },
        {
          path: '/consent/edit-collection-point',
          element: <EditCollectionPoint />
        },
        {
          path: '/consent/consent-report',
          element: <ConsentReport />
        },
        {
          path: '/consent/general-consent',
          element: <GeneralConsent />
        },
        {
          path: '/consent/transaction-consent',
          element: <TransactionConsent />
        },



        //PolicyAndNotices
        {
          path: '/policy-management/policy-and-notices',
          element: <PolicyAndNotices />
        },

        {
          path: '/policy-management/add-policy-and-notices',
          element: <AddPolicyAndNotices />
        },

        {
          path: '/policy-management/edit-policy-and-notices',
          element: <EditPolicyAndNotices />
        },

        //DataSubjectRight
        {
          path: '/data-subject/data-subject-right',
          element: <DataSubjectRight />
        },
        {
          path: '/data-subject/report-data-subject-right',
          element: <ReportDataSubjectRight />
        },
        {
          path: '/data-subject/data-subject-form',
          element: <DataSubjectForm />
        },


        //DataBreach
        {
          path: '/data-breach/data-breach',
          element: <DataBreach />
        },

        //DataDiscover
        {
          path: '/data-discover/data-analysis',
          element: <DataAnalysis />
        },
        {
          path: '/data-discover/add-data-analysis',
          element: <AddDataAnalysis />
        },
        {
          path: '/data-discover/update-data-analysis',
          element: <AddDataAnalysis />
        },
        {
          path: '/data-discover/search-data-analysis',
          element: <SearchDataAnalysis />
        },



        //LIA
        {
          path: '/lia/legitimated',
          element: <Legitimated />
        },
        {
          path: '/lia/add-legitimated',
          element: <AddLegitimated />
        },



        //Executive Support
        {
          path: '/executive-support/task',
          element: <Task />
        },
        {
          path: '/executive-support/task-detail',
          element: <TaskDetail />
        },
        {
          path: '/executive-support/summary-all-task',
          element: <SummaryAllTask />
        },

        //Template
        {
          path: '/template/audit-checklist',
          element: <AuditCheckList />
        },
        {
          path: '/template/audit-matrix',
          element: <AuditMatrix />
        },

        //404
        {
          path: "*",
          element: (
            <NotAccessPage msg="ไม่พบหน้าที่คุณค้นหา" />
          ),
        }
      ]
    },
    {
      path: '/',
      element: !isLoggedIn ? <MinimalLayout /> : <Navigate to="/" />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '/', element: <Navigate to="/login" /> },
      ],
    },
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        { path: '/data-subject/data-subject-form-url', element: <DataSubjectURL /> },
      ]
    },
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        { path: '/consent/consent-page-preview', element: <ConsentPagePreview /> },
      ]
    },
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        { path: '/consent/consent-page-preview-byId', element: <ConsentPagePreviewById /> },
      ]
    },
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        { path: '/user/resetPassword', element: <ResetPassword /> },
      ]
    },

  ]
}

export default Routes;
