import { Button, Divider, Grid, Paper, Stack, TextField, ThemeProvider, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { hostname } from '../../../hostname'
import axios from 'axios'

function ConsentPageSetting(props) {
    const navigate = useNavigate()
    const location = useLocation();
    const id = location?.state?.id
    const [rowData, setRowData] = useState({})
    const [script, setScript] = useState(``)
    const [data, setData] = useState()
    const defaultData = {
        privacySettingColor: '#ffffff',
        privacyTextColor: '#ffffff',
        privacyTextLinkColor: '#61A1F5',
        bgBtnOkTextColor: "#ffffff",
        btnTextOkBgColor: "#000000",
        bgColor: "#dedede",
        btnRejectTextColor: "#ffffff",
        btnRejectBgColor: "#68b631",
        btnConfireTextColor: "#ffffff",
        btnConfireBgColor: "#68b631",
    }
    const scriptGeneratePopup = (rowData) => {
        let tempScript = `<script>window.onload = (event)=>{
            var css = document.createElement('link');
            css.rel = "stylesheet"
            css.href = '${process.env.REACT_APP_END_POINT_API}/public/cookie-consent.css'
            document.getElementsByTagName('head')[0].appendChild(css);
            var script = document.createElement('script');
            script.onload = function () {
             CSent.inititail({
                webkit: "Google",
                text: "นโยบายเกี่ยวกับคุกกี้ (Cookie Policy) <a class='fd924-privacy-link' href='https://www.foremostthailand.com/%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%A3%E0%B8%B2/%E0%B8%99%E0%B9%82%E0%B8%A2%E0%B8%9A%E0%B8%B2%E0%B8%A2%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B9%80%E0%B8%9B%E0%B9%87%E0%B8%99%E0%B8%AA%E0%B9%88%E0%B8%A7%E0%B8%99%E0%B8%95%E0%B8%B1%E0%B8%A7'   target='_blank' >รายละเอียดเพิ่มเติม</a>",
                titleModal: "Manage Consent Preferences",
                subtitleModal: "นโยบายเกี่ยวกับคุกกี้ (Cookie Policy) <a class='fd924-privacy-link' href='https://www.foremostthailand.com/%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%A3%E0%B8%B2/%E0%B8%99%E0%B9%82%E0%B8%A2%E0%B8%9A%E0%B8%B2%E0%B8%A2%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B9%80%E0%B8%9B%E0%B9%87%E0%B8%99%E0%B8%AA%E0%B9%88%E0%B8%A7%E0%B8%99%E0%B8%95%E0%B8%B1%E0%B8%A7'   target='_blank' >รายละเอียดเพิ่มเติม</a>",
                purposes: [{id: 27, title: 'Marketing : Marketing',description: 'Marketing',textMore: '',urlMore: '',hasAllow: false},{id: 26, title: 'Promotion : Promotion',description: 'Promotion',textMore: '',urlMore: '',hasAllow: false}],
                btnPrivacyText: '',
                btnTextOK: '',
                btnRejectText: '',
                btnConfireText: '',
                labeltextPowerText: '',
                powerByText: '',
                ciColor: {
                  footerPrivacyCompany   :{
                    btnTextOkBgColor: '${rowData.btnTextOkBgColor}',
                    bgBtnOkTextColor: '${rowData.bgBtnOkTextColor}',
                    privacySettingColor: '${rowData.privacySettingColor}',
                    privacyTextColor: '${rowData.privacyTextColor}',
                    privacyTextLinkColor: '${rowData.privacyTextLinkColor}',
                  },
                  iconCookie: {
                    bgColor: '${rowData.bgColor}',
                  },
                  modalCompany: {
                    modalTitleColor: '${rowData.modalTitleColor}',
                    modelSubTitleColor: '${rowData.modelSubTitleColor}',
                    btnRejectTextColor: '${rowData.btnRejectTextColor}',
                    btnRejectBgColor: '${rowData.btnRejectBgColor}',
                    btnConfireTextColor: '${rowData.btnConfireTextColor}',
                    btnConfireBgColor: '${rowData.btnConfireBgColor}',
                    purposeTextColor: '${rowData.purposeTextColor}',
                    purposeDetailText: '${rowData.purposeDetailText}',
                    labeltextPowerColor: '${rowData.labeltextPowerColor}',
                    powerByColor: '',
                  }
                }
             });
            };
            script.src = '${process.env.REACT_APP_END_POINT_API}/public/cookie-consent.js'
            document.getElementsByTagName('head')[0].appendChild(script);
            }</script>
        `
        setScript(tempScript)
    }
    const scriptGenerateCookie = (rowData) => {
        let tempScript = `<script>window.onload = (event)=>{
            var css = document.createElement('link');
            css.rel = "stylesheet"
            css.href = '${process.env.REACT_APP_END_POINT_API}/public/cookie-consent.css'
            document.getElementsByTagName('head')[0].appendChild(css);
            var script = document.createElement('script');
            script.onload = function () {
             CSent.inititail({
                webkit: "Google",
                text: "นโยบายเกี่ยวกับคุกกี้ (Cookie Policy)  <a class='fd924-privacy-link' href='https://www.foremostthailand.com/%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%A3%E0%B8%B2/%E0%B8%99%E0%B9%82%E0%B8%A2%E0%B8%9A%E0%B8%B2%E0%B8%A2%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B9%80%E0%B8%9B%E0%B9%87%E0%B8%99%E0%B8%AA%E0%B9%88%E0%B8%A7%E0%B8%99%E0%B8%95%E0%B8%B1%E0%B8%A7'   target='_blank' >รายละเอียดเพิ่มเติม</a>",
                titleModal: "Manage Consent Preferences",
                subtitleModal: "นโยบายเกี่ยวกับคุกกี้ (Cookie Policy)  <a class='fd924-privacy-link' href='https://www.foremostthailand.com/%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%A3%E0%B8%B2/%E0%B8%99%E0%B9%82%E0%B8%A2%E0%B8%9A%E0%B8%B2%E0%B8%A2%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B9%80%E0%B8%9B%E0%B9%87%E0%B8%99%E0%B8%AA%E0%B9%88%E0%B8%A7%E0%B8%99%E0%B8%95%E0%B8%B1%E0%B8%A7'   target='_blank' >รายละเอียดเพิ่มเติม</a>",
                purposes: [{id: 27, title: 'Marketing : Marketing',description: 'Marketing',textMore: '',urlMore: '',hasAllow: false},{id: 26, title: 'Promotion : Promotion',description: 'Promotion',textMore: '',urlMore: '',hasAllow: false}],
                btnPrivacyText: '',
                btnTextOK: '',
                btnRejectText: '',
                btnConfireText: '',
                labeltextPowerText: '',
                powerByText: '',
                ciColor: {
                  footerPrivacyCompany   :{
                    btnTextOkBgColor: '${rowData.btnTextOkBgColor}',
                    bgBtnOkTextColor: '${rowData.bgBtnOkTextColor}',
                    privacySettingColor: '${rowData.privacySettingColor}',
                    privacyTextColor: '${rowData.privacyTextColor}',
                    privacyTextLinkColor: '${rowData.privacyTextLinkColor}',
                  },
                  iconCookie: {
                    bgColor: '${rowData.bgColor}',
                  },
                  modalCompany: {
                    modalTitleColor: '${rowData.modalTitleColor}',
                    modelSubTitleColor: '${rowData.modelSubTitleColor}',
                    btnRejectTextColor: '${rowData.btnRejectTextColor}',
                    btnRejectBgColor: '${rowData.btnRejectBgColor}',
                    btnConfireTextColor: '${rowData.btnConfireTextColor}',
                    btnConfireBgColor: '${rowData.btnConfireBgColor}',
                    purposeTextColor: '${rowData.purposeTextColor}',
                    purposeDetailText: '${rowData.purposeDetailText}',
                    labeltextPowerColor: '${rowData.labeltextPowerColor}',
                    powerByColor: '${rowData.powerByColor}',
                  }
                }
             });
            };
            script.src = '${process.env.REACT_APP_END_POINT_API}/public/cookie-consent.js'
            document.getElementsByTagName('head')[0].appendChild(script);
            }</script>
            <fd924-footer-privacy>
		    </fd924-footer-privacy>
        `
        setScript(tempScript)
    }

    const handleChange = (e, type) => {
        let newData
        if (e === 'reset') {
            newData = {
                privacySettingColor: '#ffffff',
                privacyTextColor: '#ffffff',
                privacyTextLinkColor: '#61A1F5',
                bgBtnOkTextColor: "#ffffff",
                btnTextOkBgColor: "#000000",
                bgColor: "#dedede",
                btnRejectTextColor: "#ffffff",
                btnRejectBgColor: "#68b631",
                btnConfireTextColor: "#ffffff",
                btnConfireBgColor: "#68b631",
            }
            setRowData(newData)
            scriptGenerateCookie(newData)
        } else {
            newData = { ...rowData, [e.target.name]: e.target.value }
            setRowData(newData)
            if (type === 'footer') {
                scriptGenerateCookie(newData)
            } else {
                scriptGeneratePopup(newData)
            }
        }
    }


    const getCookieConsentListByID = async () => {
        try {
            let res = await axios({
                url: `${hostname}/consent/getCookieConsentListByID`,
                method: "POST",
                data: {
                    "ConsentCookieID": id
                }
            })
            if (res.data.status === "success") {
                res.data.ConsentCookie[0].Script += `<fd924-footer-privacy>
                </fd924-footer-privacy>`
                setData(res.data.ConsentCookie[0])
                setScript(res.data.ConsentCookie[0].Script)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateConsentPage = async () => {
        console.log('first', script)
        try {
            let res = await axios({
                url: `${hostname}/consent/updateConsentPage`,
                method: "POST",
                data: { ...data, Script: script }
            })
            if (res.data.status === "success") {
                { navigate('/consent/cookie-consent') }
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getCookieConsentListByID()
    }, [])
    return (
        <>
            <Stack spacing={2} >
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography variant='h3'>Cookie page setting</Typography>
                    <Stack direction='row' justifyContent='flex-end' spacing={1}>
                        <Button variant='contained' color='success' sx={{ color: 'white' }} onClick={() => updateConsentPage()}>Save</Button>
                        <Button variant='contained' color='error' onClick={() => navigate('/consent/cookie-consent')}>Cancel</Button>
                    </Stack>
                </Stack>
                <Paper elevation={1} sx={{ padding: 2 }}>
                    <Grid container>
                        <Grid lg={4} sm={12} xs={12}>
                            <Stack sx={{ mb: 4 }}>
                                <Typography variant='title'>Footer</Typography>
                                <Divider sx={{ mb: 2, mt: 1 }} />
                                <Grid container xs={12} alignItems='center'>
                                    <Grid lg={6.5} sm={4} xs={5}>
                                        <Stack spacing={3}>
                                            <Typography variant='subtitle1'>OK button color</Typography>
                                            <Typography variant='subtitle1'>OK font color</Typography>
                                            <Typography variant='subtitle1'>Setting font color</Typography>
                                            <Typography variant='subtitle1'>Text Privacy color</Typography>
                                            <Typography variant='subtitle1'>Text More font color</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid lg={4} sm={3} xs={7}>
                                        <Stack spacing={1}>
                                            <TextField type='color' size='small' name='btnTextOkBgColor' value={rowData.btnTextOkBgColor || defaultData.btnTextOkBgColor} onChange={(e) => handleChange(e, 'footer')} />
                                            <TextField type='color' size='small' name='bgBtnOkTextColor' value={rowData.bgBtnOkTextColor || defaultData.bgBtnOkTextColor} onChange={(e) => handleChange(e, 'footer')} />
                                            <TextField type='color' size='small' name='privacySettingColor' value={rowData.privacySettingColor || defaultData.privacySettingColor} onChange={(e) => handleChange(e, 'footer')} />
                                            <TextField type='color' size='small' name='privacyTextColor' value={rowData.privacyTextColor || defaultData.privacyTextColor} onChange={(e) => handleChange(e, 'footer')} />
                                            <TextField type='color' size='small' name='privacyTextLinkColor' value={rowData.privacyTextLinkColor || defaultData.privacyTextLinkColor} onChange={(e) => handleChange(e, 'footer')} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack sx={{ mb: 4 }}>
                                <Typography variant='title'>Popup</Typography>
                                <Divider sx={{ mb: 2, mt: 1 }} />
                                <Grid container xs={12} alignItems='center'>
                                    <Grid lg={6.5} sm={4} xs={5}>
                                        <Stack spacing={3}>
                                            <Typography variant='subtitle1'>Confirm all button color</Typography>
                                            <Typography variant='subtitle1'>Confirm all font color</Typography>
                                            <Typography variant='subtitle1'>Reject all button color</Typography>
                                            <Typography variant='subtitle1'>Reject all font color</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid lg={4} sm={3} xs={7}>
                                        <Stack spacing={1}>
                                            <TextField fullWidth type='color' size='small' name='btnConfireBgColor' value={rowData.btnConfireBgColor || ''} onChange={(e) => handleChange(e, 'popup')} />
                                            <TextField fullWidth type='color' size='small' name='btnConfireTextColor' value={rowData.btnConfireTextColor || ''} onChange={(e) => handleChange(e, 'popup')} />
                                            <TextField fullWidth type='color' size='small' name='btnRejectBgColor' value={rowData.btnRejectBgColor || ''} onChange={(e) => handleChange(e, 'popup')} />
                                            <TextField fullWidth type='color' size='small' name='btnRejectTextColor' value={rowData.btnRejectTextColor || ''} onChange={(e) => handleChange(e, 'popup')} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack sx={{ mb: 4 }}>
                                <Typography variant='title'>Float button</Typography>
                                <Divider sx={{ mb: 2, mt: 1 }} />
                                <Grid container xs={12} alignItems='center'>
                                    <Grid lg={6.5} sm={4} xs={5}>
                                        <Stack spacing={3}>
                                            <Typography variant='subtitle1'>Cookie button color</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid lg={4} sm={3} xs={7}>
                                        <Stack spacing={1}>
                                            <TextField fullWidth type='color' size='small' name='bgColor' value={rowData.bgColor || ''} onChange={(e) => handleChange(e, 'popup')} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid lg={8} sm={12} xs={12}>
                            <Stack sx={{ m: 4 }} >
                                <Grid container xs={12} alignItems='center' border={1}>
                                    <iframe width='100%' height='600' srcDoc={script} />
                                </Grid>
                            </Stack>
                            <Stack sx={{ mb: 4 }} spacing={2} >
                                <Stack direction='row' spacing={2} justifyContent='center'>
                                    <Button variant='contained' color='success' onClick={() => { handleChange('reset') }}>Reset</Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </Stack>
        </>
    )
}

export default ConsentPageSetting