import React, { useState, forwardRef, useImperativeHandle } from "react";
import Backdrop from '@mui/material/Backdrop';
import { Box, Fab } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from '../assets/images/logo/privacy_pro_badge.png';

const LoadingModal = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            setOpen: setOpen,
        }
    })
    const [open, setOpen] = useState(false)

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: 99999 }}
            open={open}
        >
            <Box sx={{
                margin: 1,
                position: "relative"
            }}>
                <Fab color="primary"  >
                    <img src={Logo} alt="logo" width='50' height='50' />
                </Fab>
                <CircularProgress
                    size={78}
                    thickness={5}
                    color="inherit"
                    sx={{
                        position: "absolute",
                        top: -11,
                        left: -11,
                        zIndex: 1300
                    }}
                />
            </Box>
        </Backdrop >
    )
})

export default LoadingModal