import { IconDatabase, IconSend,IconChartPie,IconFolders} from '@tabler/icons';

const icons = {
    IconDatabase,
    IconChartPie,
    IconSend,
    IconFolders
  
};

const DataDiscoverMenu = 
    {
        id: 'Data Discover',
        title: 'Data Discover',
        type: 'collapse',
        icon: icons.IconDatabase,
        children: [
            {
                id: 'Data Analysis',
                title: 'Data Analysis',
                type: 'item',
                url: '/data-discover/data-analysis',
                icon: icons. IconFolders,
                breadcrumbs: false,
               
            },
            {
                id: 'Data Connection Setting',
                title: 'Data Connection Setting',
                type: 'item',
                url: '/data-discover/data-connection',
                icon: icons. IconFolders,
                breadcrumbs: false
            },
           
        ]
    }

export default DataDiscoverMenu
