import { IconListDetails, IconSend,IconChartPie} from '@tabler/icons';

const icons = {
    IconListDetails,
    IconChartPie,
    IconSend
  
};

const DataSubjectMenu = 
    {
        id: 'Data Subject Right Management',
        title: 'Data Subject Right Management',
        type: 'collapse',
        icon: icons.IconListDetails,
        children: [
            {
                id: 'Data Subject Request',
                title: 'รายการขอใช้สิทธิตามกฏหมายคุ้มครองข้อมูลส่วนบุคคล',
                type: 'item',
                url: '/data-subject/data-subject-right',
                icon: icons.IconSend,
                breadcrumbs: false,
               
            },
            {
                id: 'Data Subject Report',
                title: 'รายงานสรุปขอใช้สิทธิตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล',
                type: 'item',
                url: '/data-subject/report-data-subject-right',
                icon: icons.IconChartPie,
                breadcrumbs: false
            },
           
        ]
    }

export default DataSubjectMenu
