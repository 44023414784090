import MasterDataMenu from './pdpa/MasterDataMenu';
import PDInventoryMenu from './pdpa/PDInventoryMenu';
import DPIARiskAssessmentMenu from './pdpa/DPIA&RiskAssessmentMenu';
import AssuranceAndGapManagementMenu from './pdpa/AssuranceAndGapManagementMenu';
import ConsentMenu from './SelectedMenu/ConsentMenu'
import PolicyMenu from './SelectedMenu/PolicyMenu'
import DataSubjectMenu from './SelectedMenu/DataSubjectMenu'
import DataBreachMenu from './SelectedMenu/DataBreachMenu'
import DataDiscoverMenu from './SelectedMenu/DataDiscoverMenu'
import LIAMenu from './SelectedMenu/LIAMenu'
import ExecutiveMenu from './SelectedMenu/ExecutiveMenu'
import TemplateMenu from './SelectedMenu/TemplateMenu'

const pdpa = {
    id: 'pdpa',
    title: '',
    type: 'group',
    children: [PDInventoryMenu, , DPIARiskAssessmentMenu, AssuranceAndGapManagementMenu, MasterDataMenu, ConsentMenu, PolicyMenu, DataSubjectMenu, DataBreachMenu, DataDiscoverMenu
        , LIAMenu, ExecutiveMenu, TemplateMenu]
};

export default pdpa;
