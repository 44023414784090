import PropTypes from 'prop-types';
import { useContext } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Stack, Typography, } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { UserContext } from '../../../components/contexts/UserContext'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const { userDataContext } = useContext(UserContext)
    const userProfile = userDataContext?.userProfile

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    pr: 3,
                    justifyContent: 'space-between',
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <LogoSection />
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            <Box mr={3}>
                <Stack direction="column" alignItems="flex-start" justifyContent="center">
                    <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                        {`${userProfile?.FirstName} ${userProfile?.LastName}`}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: 'white' }}>{userProfile?.CompanyName}</Typography>
                </Stack>
            </Box>
            <Box display="flex" alignItems="center">
                {/* <NotificationSection /> */}

                <ProfileSection />
            </Box>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
