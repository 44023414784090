import { useContext, forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react'
import { Typography } from '@mui/material';

import NavGroup from './NavGroup';

import axios from 'axios';
import { hostname } from '../../../../hostname';

import * as TablerIcon from '@tabler/icons';
// ==============================|| SIDEBAR MENU LIST ||============================== //
import { PermissionContext } from "../../../../components/contexts/PermissionContext";
import { UserContext } from '../../../../components/contexts/UserContext'

const MenuList = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            searchMenuItem: searchMenuItem
        }
    })

    const [menuItemFilter, setMenuItemFilter] = useState([])
    const { setPageAction } = useContext(PermissionContext)
    const { userDataContext } = useContext(UserContext)
    const userProfile = userDataContext?.userProfile

    const getPermissionProgram = async () => {
        try {
            let res = await axios({
                method: "post",
                url: `${hostname}/user/getPermissionProgram`,
                data: {},
            });
            if (res.data.status === "success") {
                createMenu(res.data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const createMenu = async (responseMenu) => {
        let menuItems = {
            items: [{
                id: 'pdpa',
                title: '',
                type: 'group',
                children: []
            }]
        }
        let menuItemTemp = [...menuItems.items]
        let newMenuArr = []
        for (let parent of responseMenu) {
            let newParent = {
                "ProgramID": parent.ProgramID,
                "id": parent.Code,
                "title": findLanguage(parent),
                "type": parent.type,
                "url": parent.Action,
                "breadcrumbs": false,
                "icon": TablerIcon[parent.Icon],
            }

            let newChildrenArr = []
            for (let children of parent.items) {
                let newChildren = {
                    "ProgramID": children.ProgramID,
                    "id": children.Code,
                    "title": findLanguage(children),
                    "type": children.type,
                    "url": children.Action,
                    "breadcrumbs": false,
                    "icon": TablerIcon[children.Icon],
                }
                newChildrenArr.push(newChildren)
            }
            newParent.children = newChildrenArr
            newMenuArr.push(newParent)
        }
        menuItemTemp[0].children = newMenuArr
        setMenuItemFilter(menuItemTemp)
    }

    const findLanguage = (parent) => {
        if (userProfile?.Language === 'EN') {
            return parent.Description
        }
        if (userProfile?.Language === 'TH') {
            if (parent.DescriptionTH !== null) {
                return parent.DescriptionTH
            } else {
                return parent.Description
            }
        }
    }

    const findMenuCode = () => {
        if (menuItemFilter.length) {
            let menu_code_temp = ''
            let collapse_menu = []
            for (let menu_item of menuItemFilter[0]?.children) {
                let filter_menu_code = menu_item?.children?.find(children_item => children_item?.url === window.location.pathname)
                if (filter_menu_code !== undefined) {
                    menu_code_temp = filter_menu_code
                } else {
                    if (menu_item.type !== "collapse") {
                        collapse_menu.push(menu_item)
                        let filter_collapse_menu = collapse_menu?.find(collapse_menu_item => {
                            let split_path = '/' + window.location.pathname.split('/')[1]
                            if (collapse_menu_item?.url === split_path) {
                                return true;
                            }
                        })
                        menu_code_temp = filter_collapse_menu
                    }
                }
            }
            let menu_code = menu_code_temp?.id
            let programID = menu_code_temp?.ProgramID
            checkPermissionAccess(menu_code, programID)
        }
    }

    const checkPermissionAccess = async (menu_code, programID) => {
        try {
            let res = await axios({
                method: "post",
                url: `${hostname}/user/checkPermissionAccess`,
                data: {
                    ProgramID: programID,
                    Program: menu_code
                },
            });
            if (res.data.status === "success") {
                setPageAction(res.data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const searchMenuItem = (searchValue) => {
        // if (searchValue !== undefined) {
        //     let menuItemsArr = []
        //     for (let menuItem of menuItemFilter[0]?.children) {
        //         for (let children_item of menuItem?.children) {
        //             menuItemsArr.push(children_item)
        //         }
        //     }
        //     let menuItems = [...menuItemsArr]
        //     let menuItemFilterTemp = [...menuItemFilter]
        //     const filterMenu = menuItems?.filter(item => item.title.toString().toLowerCase().includes(searchValue.toLowerCase()))
        //     menuItemFilterTemp[0].children = filterMenu
        //     setMenuItemFilter(menuItemFilterTemp)
        // }
    }

    // console.log(menuItemFilter)
    const navItems = menuItemFilter.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    useEffect(() => {
        getPermissionProgram()
    }, [userProfile])

    useEffect(() => {
        findMenuCode()
    }, [window.location.pathname, menuItemFilter])

    return <>
        {navItems}
    </>;
})

export default MenuList;
