// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconHome } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconHome
};

const main = {
    id: 'main',
    title: '',
    type: 'group',
    children: [
        {
            id: 'Home',
            title: 'Home',
            type: 'item',
            url: '/',
            icon: icons.IconHome,
            breadcrumbs: false
        },
    ]
};

export default main;
