import {IconList, IconCertificate} from '@tabler/icons';

const icons = {
    IconList,
    IconCertificate
};

const LIAMenu = 
    {
        id: 'LIA Management',
        title: 'LIA Management',
        type: 'collapse',
        icon: icons.IconCertificate,
        children: [
            {
                id: 'Legitimated',
                title: 'แบบประเมินประโยชน์โดยชอบด้วยกฎหมาย',
                type: 'item',
                url: '/lia/legitimated',
                icon: icons.IconList,
                breadcrumbs: false,
               
            },
          
 
        ]
    }

export default LIAMenu
