import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Stack,
  Switch,
  TextField,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  Avatar
} from "@mui/material";
import axios from "axios";
import TextFieldCus from "../../../components/form/TextFieldCus";
import { ClientJS } from "clientjs";
import Snackbar from "../../../utils/Snackbar";
import { useNavigate } from "react-router";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import InputMask from "react-input-mask";

function ConsentPagePreview() {
  let clientJs = new ClientJS();
  const navigate = useNavigate();
  const hostname = process.env.REACT_APP_API;
  const CollectionPointID = new URLSearchParams(window.location.search).get(
    "CollectionPointID"
  );
  const CompanyID = new URLSearchParams(window.location.search).get(
    "CompanyID"
  );
  const WebsiteID = new URLSearchParams(window.location.search).get(
    "WebsiteID"
  );
  const Browser = clientJs.getBrowser();
  const [dataPreview, setDataPreview] = useState([]);
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState(false);
  const [collectionPointList, setCollectionPointList] = useState([]);
  const [rowData, setRowData] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState({
    status: false,
    type: "",
    msg: "",
  });
  const [dataPurpose, setDataPurpose] = useState([]);

  Yup.addMethod(Yup.mixed, "validatePersonalId", function (message) {
    return this.test("isValidPersonalId", message, function (value) {
      const { path, createError } = this;
      if (value === undefined || value === null || value === "") {
        return createError({ path, message: message ?? "กรุณากรอกเลขบัตรประชาชน" });
      }
      if (value.includes('_')) {
        return createError({ path, message: message ?? "กรุณากรอกเลขบัตรประชาชนให้ครบ" });
      }
      return true;
    });
  });

  const validateSchema = Yup.object().shape({
    CitizenID: Yup.mixed().validatePersonalId().required("กรุณากรอกเลขบัตรประชาชน"),
    Email: Yup.string().email("กรุณากรอกอีเมล์ให้ถูกต้อง").required("กรุณากรอกอีเมล์"),
    NameSurname: Yup.string().required("กรุณากรอกชื่อ"),
    Tel: Yup.string().required("กรุณากรอกเบอร์โทรศัพท์").min(10, "กรุณากรอกเบอร์โทรศัพท์ให้ครบ"),
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validateSchema),
    mode: "onChange"
  });

  const handleChange = (event) => {
    if (event.target.checked) {
      let data = collectionPointList.find(
        (item) => item.PurposeID == event.target.value
      );
      let temp = [];
      temp = data;
      dataPurpose.push(temp);
      temp = [];
    } else {
      let index = dataPurpose.findIndex(
        (item) => item.PurposeID == event.target.value
      );
      dataPurpose.splice(index, 1);
    }
    setStatus({
      ...status,
      [event.target.name]: event.target.value,
    });
  };
  const handleUpdate = (e) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };

  const handleChangeAcc = (event) => {
    setChecked(event.target.checked);
  };

  const getConsentPage = async () => {
    try {
      let res = await axios({
        url: `${hostname}/consent/getConsentPage`,
        method: "POST",
        headers: {
          checkDevice: false,
        },
        data: {
          CollectionPointID: CollectionPointID,
          CompanyID: CompanyID,
        },
      });
      if (res.data.status === "success") {
        setDataPreview(res.data.data[0]);
      } else {
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCollectionPointItemList = async () => {
    try {
      let res = await axios({
        url: `${hostname}/consent/getCollectionPointItemList`,
        method: "POST",
        headers: {
          checkDevice: false,
        },
        data: {
          CollectionPointID: CollectionPointID,
          CompanyID: CompanyID,
        },
      });
      if (res.data.status === "success") {
        setCollectionPointList(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addConsentPagePreview = async () => {
    try {
      rowData.CitizenID = rowData.CitizenID.replace(/-/g, "");
      let res = await axios({
        url: `${hostname}/consent/addConsentPagePreview`,
        method: "POST",
        headers: {
          checkDevice: false
        },
        data: {
          CompanyID: CompanyID,
          CollectionPointID: CollectionPointID,
          WebsiteID: WebsiteID,
          CitizenID: rowData.CitizenID,
          Email: rowData.Email,
          NameSurname: rowData.NameSurname,
          Tel: rowData.Tel,
          FromBrowser: Browser,
          Status_Consent: checked,
          purpose: dataPurpose,
        },
      });
      if (
        res.data.status === "success" ||
        res.data.status === "success-transaction"
      ) {
        setOpenSnackbar({
          status: true,
          type: "success",
          msg: "เพิ่มข้อมูลสำเร็จ",
        });
        window.location.href = dataPreview.LabelLinkToPolicyURL;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkAccessToken = async () => {
    const CompanyAccessToken = new URLSearchParams(window.location.search).get(
      "CompanyID"
    );
    const CollectionPointAccessToken = new URLSearchParams(
      window.location.search
    ).get("CollectionPointID");
    const WebsiteAccessToken = new URLSearchParams(window.location.search).get(
      "WebsiteID"
    );

    let allAccessToken = await getAllAccessToken();
    let matchCompanyIDToken = allAccessToken.some(
      (item) => item.access_token === CompanyAccessToken
    );
    let matchCollectionPointIDToken = allAccessToken.some(
      (item) => item.access_token === CollectionPointAccessToken
    );
    let matchWebsiteIDToken = allAccessToken.some(
      (item) => item.access_token === WebsiteAccessToken
    );
    if (
      matchCompanyIDToken &&
      matchCollectionPointIDToken &&
      matchWebsiteIDToken
    ) {
      return true;
    }
  };

  const getAllAccessToken = async () => {
    try {
      let res = await axios({
        url: `${hostname}/user/getAllAccessToken`,
        method: "POST",
        data: {},
        headers: {
          checkDevice: false,
        },
      });
      if (res.data.status === "success") {
        return res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    if (await checkAccessToken()) {
      getConsentPage();
      getCollectionPointItemList();
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Stack
        sx={{
          background: `${dataPreview.HeaderBGColor}`,
          backgroundImage: `url(${dataPreview?.file?.HeaderBGImage?.fileUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: 'center',
          height: 170,
          width: '100%',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {dataPreview?.file?.HeaderLogo?.fileUrl !== undefined &&
          <img src={dataPreview?.file?.HeaderLogo?.fileUrl} style={{ width: '150px', height: '150px', left: 0, position: 'absolute' }} />
        }
        <Typography sx={{
          fontSize: '25px', color: `${dataPreview?.HeaderFontColor}`
        }}>{dataPreview.HeaderLabel}</Typography>
      </Stack>
      <Grid
        container
        justifyContent="center"
        sx={{
          background: `${dataPreview.BodyBGColor}`,
          backgroundImage: `url(${dataPreview?.file?.BodyBGImage?.fileUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: 'center',
        }}>
        <Grid item xs={8}>
          <Grid
            container
            xs={12}
            justifyContent="center"
            sx={{ marginTop: "3%" }}
          >
            <Grid container xs={2} sx={{ marginTop: "1%" }}>
              <Stack spacing={5}>
                <Typography variant="subtitle1" whiteSpace="nowrap">
                  ID Card Number: *
                </Typography>
                <Typography variant="subtitle1" whiteSpace="nowrap">
                  Email: *{" "}
                </Typography>
                <Typography variant="subtitle1" whiteSpace="nowrap">
                  Name - Surname: *
                </Typography>
                <Typography variant="subtitle1" whiteSpace="nowrap">
                  Tel: *
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <Stack spacing={3}>
                <InputMask
                  mask="9-9999-999-9999-9"
                  {...register("CitizenID", {
                    onChange: (e) => {
                      handleUpdate(e)
                    }
                  })}
                >
                  {() => (
                    <TextField
                      name="CitizenID"
                      type="tel"
                      size="small"
                      variant="outlined"
                      error={errors?.CitizenID}
                      helperText={errors?.CitizenID?.message}
                    />
                  )}
                </InputMask>
                <TextField
                  fullWidth
                  size="small"
                  name="Email"
                  {...register("Email", {
                    onChange: (e) => {
                      handleUpdate(e)
                    }
                  })}
                  error={errors?.Email}
                  helperText={errors?.Email?.message}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="NameSurname"
                  {...register("NameSurname", {
                    onChange: (e) => {
                      handleUpdate(e)
                    }
                  })}
                  error={errors?.NameSurname}
                  helperText={errors?.NameSurname?.message}
                />
                <InputMask
                  mask="9999999999"
                  maskChar=""
                  {...register("Tel", {
                    onChange: (e) => {
                      handleUpdate(e)
                    }
                  })}
                >
                  {() => (
                    <TextField
                      name="Tel"
                      fullWidth
                      size="small"
                      type="tel"
                      error={errors?.Tel}
                      helperText={errors?.Tel?.message}
                    />
                  )}
                </InputMask>
              </Stack>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid
              container
              item
              xs={10}
              style={{ justifyContent: "center", marginTop: 2 }}
            >
              <br />
              <Typography
                style={{ color: `${dataPreview.BodyTopDerscriptionFontColor}` }}
              >
                {dataPreview.BodyTopDescription}
              </Typography>
              <Grid
                container
              >
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  {collectionPointList.map((item) => (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Switch
                          color="success"
                          value={item.PurposeID}
                          onChange={handleChange}
                          name="status_purpose"
                        />
                        {item.PurposeCategoryName}
                      </Stack>
                    </>
                  ))}
                </Grid>
              </Grid>
              <Typography
                style={{
                  color: `${dataPreview.BodyBottomDescriptionFontColor}`,
                }}
              >
                {dataPreview.BodyBottomDescription}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  name={"ConsentAccept"}
                  checked={checked}
                  onChange={(e) => handleChangeAcc(e)}
                />
                ยอมรับคุกกี้เพื่อพัฒนาประสบการณ์ใช้งาน
                <a href={dataPreview.LabelLinkToPolicyURL} target="_blank">อ่านเพิ่มเติม</a>
              </Stack>
              <Grid
                container
                direction="row"
                justifyContent="center"
                style={{ marginTop: 10, marginBottom: 60 }}
              >
                <Grid item xs={2} style={{ marginRight: "10px" }}>
                  <Button
                    variant="contained"
                    style={{
                      background: `${dataPreview.OKButtonBGColor}`,
                      color: `${dataPreview.OKButtonFontColor}`,
                    }}
                    fullWidth
                    onClick={handleSubmit(addConsentPagePreview)}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    style={{
                      background: `${dataPreview.CancelButtonBGColor}`,
                      color: `${dataPreview.CancelButtonFontColor}`,
                    }}
                    fullWidth
                    onClick={() => {
                      setRowData({});
                      window.close();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar values={openSnackbar} setValues={setOpenSnackbar} />
    </>
  );
}

export default ConsentPagePreview;
